.red {
    color: #b22 !important;
}
.green {
    color: #037d03 !important;
}
.yellow {
    color: yellow;
}
.lime {
    color: lime;
}
