.pp_logo {
    height: 36px;
    text-align: right;
    background: url(../images/pp.svg) no-repeat 0;
}
.yo_logo {
    background: url(../images/YooMoney.svg) no-repeat 0;
    height: 50px;
    width: 200px;
    margin: 0 auto 36px;
}
.layer-1 {
    z-index: 10;
    position: relative;
}
.muted {
    color: #777 !important;
}
.faded {
    opacity: 0.4;
}
.blur {
    opacity: 0.7;
}
.italic {
    font-style: italic !important;
}
._bold {
    font-weight:bold;
}
._nowrap {
    white-space: nowrap;
}
._link {
    cursor: pointer;
}
.flex {
    display: flex;
}
.flex.justify-right {
    justify-content: flex-end;
}
.flex.justify-left {
    justify-content: flex-start;
}
.flex.justify-between {
    justify-content: space-between;
}
.flex.column {
    flex-direction: column;
}
.clear {
    clear: both;
}
.mr {
    margin-right: 5px;
}
.mpx4 {
    margin: 4px !important;
}
.m-0 {
    margin: 0 !important;
}
.p-0 {
    padding: 0 !important;
}
.m-1 {
    margin: 10px !important;
}
.mt-1 {
    margin-top: 10px !important;
}
.mb-1 {
    margin-bottom: 10px !important;
}
.ml-1 {
    margin-left: 10px !important;
}
.mr-1 {
    margin-right: 10px !important;
}
.mx-1 {
    margin: 0 10px !important;
}
.my-1 {
    margin: 10px 0 !important;
}
.my-2 {
    margin: 20px 0 !important;
}
.mt-2 {
    margin-top: 20px !important;
}
.mb-2 {
    margin-bottom: 20px !important;
}
.ml-2 {
    margin-left: 20px !important;
}
.mr-2 {
    margin-right: 20px !important;
}
.ml-4 {
    margin-left: 40px !important;
}
.mx-4 {
    margin: 0 40px !important;
}
.mx-auto {
    margin: 0 auto !important;
}
.p-1 {
    padding: 10px !important;
}
.pl-1 {
    padding-left: 10px !important;
}
.pr-1 {
    padding-right: 10px !important;
}
.pt-1 {
    padding-top: 10px !important;
}
.pb-1 {
    padding-bottom: 10px !important;
}
.px-1 {
    padding: 0 10px !important;
}
.py-1 {
    padding: 10px 0 !important;
}
.lh16 {
    line-height: 16px;
}
.lh24 {
    line-height: 24px;
}
.lh32 {
    line-height: 32px;
}
.hidden {
    visibility: hidden;
}
.w280 {
    width: 280px;
}
.h28 {
    height: 28px;
}
._close {
    position:absolute;
    top:-12px;
    right:-12px;
    cursor:pointer;
    z-index:120;
    padding: 5px;
}
._closeinner {
    background:url(../images/close.png);
    height:16px;
    width:16px;
}
.right {
    text-align: right;
}
.btn {
    padding: 3px;
    cursor: pointer;
}
.btn.selected {
    background-color: #aaa;
}
a.solidgray {
    text-decoration:none;
    color:#aaa !important;
    font-weight:bold;
}
.center,
.center20 {
    text-align:center !important;
}
.center20 {
    margin:10px 0;
}
.centered {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5002;
}
.clearfix:after {
    content: ".";
    clear: both;
    display: block;
    visibility: hidden;
    height: 0px;
}
.abs {
    position: absolute;
    top: 10px;
    left: 10px;
}
.event-th-img,
.image32 {
    height: 32px;
    width: 32px;
}
.es_abs {
    position: absolute;
}
.es_abs {
    right: 5px;
    bottom: 5px;
}
.ov-hidden {
    overflow: hidden;
}
.overflow-y {
    overflow: auto;
}
.overflow-fixed,
.overflow-x {
    position: relative;
    margin: 0 auto;
    overflow: auto;
}
.overflow-inner-cont {
    position: relative;
    width: 1280px;
    margin: 0px auto;
}
.overflow-fixed-inner,
.overflow-inner {
    position: relative;
    height: 100%;
}
.overflow-inner {
    float: left;
}
.span_alarm {
    text-align: center;
    color: #b22;
    margin-left: 10px;
}
.small-gray-hint {
    font-size:0.8em;
    color:gray;
}
.img-middle {
    vertical-align:middle;
}
.sapphire-lighted {
    z-index: 4900;
}
.sapphire-background {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    z-index: 4800;
}
.detail {
    padding:0 5px;
    margin: 10px;
}
.scroll_to_top {
    display: none;
    position: fixed;
    width: 32px;
    height: 32px;
    bottom: 10px;
    right: 20px;
    border-radius: 4px;
    padding: 5px;
    background: #a9a38b;
    z-index: 100;
}
.scroll_to_top a {
    display: block;
    background: url(../images/totop.png);
    opacity: 0.3;
    width: 100%;
    height: 100%;
}
.scroll_to_top a:hover {
    opacity: 1;
}
.fixed-middletop-box {
    display: none;
    position: fixed;
    width: 270px;
    z-index: 2000;
    padding: 10px;
    background: #DEC79B;
    border-radius: .7em;
    border: 1px solid #D4BA8C;
    left: 50%;
    margin-left: -145px;
    top: 120px;
    box-shadow: 0px 2px 10px;
}
#main {
    height:100%;
}
#main > #background {
    height:auto;
    min-height:100%;
}
#background {
    height:100%;
    margin:0px auto;
    overflow:hidden;
    background:rgba(0,0,0,0.3);
    width:700px;
}
#content {
    height:100%;
}
.login { margin-top:10px; }
.enter {
       background: url(../images/enter.jpg);
       width: 640px;
       height: 480px;
       margin: 0 auto;
       position: relative;
}
.enter #email {
       border:medium none;
       height:17px;
       text-align:center;
       left:50%;
       margin-left:-75px;
       margin-top:-8px;
       position:absolute;
       top:50%;
       width:120px;
}
.enter #password {
       border:medium none;
       height:17px;
       text-align:center;
       left:50%;
       margin-left:-75px;
       margin-top:34px;
       position:absolute;
       top:50%;
       width:120px;
}
.register {
       font-family:tahoma;
       font-size:27px;
       color: #a36e35;
       position:absolute;
       right:10px;
       top:270px;
}
.register:hover {
    color: #ad781c;
}
.remind_password {
    font-size:13px;
    top:300px;
}
.enter_button {
    position:absolute;
    right:285px;
    top:317px;
}
#message-info {
    display:none;
    position: absolute;
    cursor:pointer;
    z-index:10000;
    box-shadow: 0px 0px 5px 0px #aaa;
}
.informate-cont {
    padding: 10px;
    background: #b0eb6b;
    border: 1px solid #63a912;
    color: #326c43;
}
#header-msg {
    position: absolute;
    font-weight: bold;
    width: 400px;
    top: 5px;
    z-index: 3000;
    left:50%;
    margin-left:-200px;
    text-align:center;
    border:1px solid #555;
}
#header-msg .success {
    padding:10px;
    background:#93ea83;
}
#header-msg .error {
    padding:10px;
    background:#ec7777;
}
.ferror {
    color: #b22;
    font-weight: bold;
    padding: 5px;
}
#mainheader {
    position: relative;
    min-height: 38px;
    width: 100%;
    z-index: 1000;
}
.header {
    /*position: fixed;*/
    background: #0f324c;
    width: 100%;
    border-bottom: 2px solid #192731;
    height: 36px;
}
.ul-list {

}
.ul-list li {
    line-height: 18px;
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.main-menu {
    float: left;
    margin-top: 9px;
}
.main-menu li {
    float: left;
    margin-left: 30px;
    font-weight: bold;
    color: #dec79b;
    font-size: 16px;
}
.user-info {
    float: right;
    margin-right: 30px;
}
.user-info li {
    float: right;
}
.head {
    background:url(../images/land.jpg) 50% 0 no-repeat;
    position: relative;
    height: 800px;
    color: #444;
}
.headnight {
    background:url(../images/landnight.jpg) 50% 0 no-repeat;
}
.head-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
}
.head-content {
    width: 593px;
    margin: 0 auto;
    max-width: none;
    text-align: center;
}
#head-logo-ru,
#head-logo-en {
    padding-top: 0;
    background-size: auto;
    height: 185px;
    width: 593px;
    padding-bottom: 0;
    margin-left: 22px;
    margin-top: 15px;
}
#head-logo-ru {
    background-image: url(../images/logo_ru.png);
}
#head-logo-en {
    background-image: url(../images/logo_en.png);
}
.ecotitle__gray {
    background-image: url(../images/logo300_gray.png);
    background-size: auto;
    width: 300px;
    height: 101px;
    margin: 40px auto;
}
.head-content h1 {
    /*letter-spacing: 1px;*/
    font-size: 30px;
    line-height: 1.1;
    font-weight: bold;
}
.head__content {
    margin: 51px 0 0 51px;
}
.head__content-playbutton_en,
.head__content-playbutton_ru {
    background-size: auto;
    cursor: pointer;
    width: 200px;
    height: 70px;
    margin: 10px auto 0;
}
.head__content-playbutton_en {
    background-image: url(../images/playbutton_en.png);
}
.head__content-playbutton_ru {
    background-image: url(../images/playbutton_ru.png);
}
.head__content-playbutton_en:hover,
.head__content-playbutton_ru:hover {
    cursor: pointer;
}
.head__content-playbutton_en:hover {
    background-image: url(../images/playbuttonactive_en.png);
}
.head__content-playbutton_ru:hover {
    background-image: url(../images/playbuttonactive_ru.png);
}
a.main-logobutton {
    display: block;
    width: 100%;
    height: 100%;
}
.head-content h2 {
    line-height: 2;
    font-size: 18px;
    margin: 0 10px;
}
.head-title {
    color: #b2a686;
}
.head-shadow {
    color: ##195f2f;
    text-shadow: 1px 1px 5px rgba(255, 255, 0, 0.5), 0 0 2px rgba(255, 255, 0, 0.5);
}
.head__secondary {
    margin-top: 28px;
}
.page_body {
    margin: 0 auto;
}
.body_news {
    max-width: 1160px;
    position: relative;
    margin: 0 auto;
}
.body_news_container {
    overflow: hidden;
    border-radius: .7em;
    background-color: #DEC79B;
    background-color: rgba(116, 50, 0, 0.07);
    border-top: 1px solid #D4BA8C;
    border-bottom: 1px solid #D4BA8C;
}
.body_news_container li {
    padding: 10px;
    float: left;
    width: 25%;
}
.videos {
    margin: 32px auto;
    width: 968px;
    overflow: hidden;
}
.body_mainmenu {
    max-width: 1160px;
    position: relative;
    margin: 40px auto 0px;
}
.chars-attrs-skills,
.body_bordered_container,
.body_mainmenu_container {
    overflow: auto;
    border-radius: .7em;
    background-color: #DEC79B;
    border-top: 1px solid #D4BA8C;
    border-bottom: 1px solid #D4BA8C;
}
.body_bordered_container {
    margin: 0 20px;
}
.body_mainmenu_container {
    width: 680px;
    margin: 0 auto;
}
.body_mainmenu_container li {
    float: left;
    padding: 15px;
    width: 140px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #855e35;
}
.body_mainmenu_container li a:hover {
    color: #bb7730;
}
.body_mainmenu__part {
    float:left;
    overflow:hidden;
}
.body_card {
    position: relative;
    overflow: hidden;
    padding: 80px 0;
}
.townline {
    position: relative;
    overflow: hidden;
    background: url(../images/townline.png) 50% 100% repeat-x;
}
#spBus {
    background: transparent url(../images/bus_sprites.png) 0 0 no-repeat;
    position: absolute;
    bottom: 10px;
    right: 65px;
    width: 128px;
    height: 62px;
    z-index: 200;
}
.main_buttons_cont {
    width: 430px;
    margin: 0px auto;
}
.main_basetext {
    color: #a36e35;
}
.main_basetitle {
    color: #a36e35;
    font-weight: bold;
    text-align: center;
    font-size: 14px;
    line-height: 16px;
}
.main_cont {
    margin: 20px 0;
}
.card-cont {
    width: 148px;
    position: relative;
    margin: 0 auto;
}
.info_cells_card_container, .body_card_container, .info_bonus_container {
    overflow: hidden;
    margin: 0 auto;
}
.body_card_container {
    width: 600px;
    min-height: 182px;
}
.info_bonus_container {
    width: 660px;
}
.info_cells_card_container {
    width: 450px;
}
.cards-inline {
    width: 450px;
    overflow: hidden;
    margin: 0 auto;
}
.cards-inline .li, .cards-inline li, .info_cells_card_container .li, .body_card_container .li, .info_bonus_container li {
    width: 148px;
    float: left;
    margin: 1px;
}
.jur-cont {
    position: relative;
    height: 224px;
}
.info_cells_card_container .cards, .body_card_container .cards {
    box-shadow: 0px 0px 5px;
}
.info_bonus_container li {
    width: 200px;
    margin: 0 10px;
}
.info_bonus_container h1, .info_bonus_container_header {
    margin-bottom: 10px;
    font-weight: bold;
    color: #044a7e;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
}
.info_bonus_container_header {
    overflow: hidden;
}
.info_bonus_container_header:after {
    content: "";
    clear: both;
}
.info_vertical {
    margin: 0 auto;
}
.info_vertical li {
    width: 100%;
    text-align: left;
    margin: 20px 0;
    clear: both;
}
.cells_128, .info_bonus_cards, .img_cont_128 {
    margin: 20px auto;
    width: 128px;
    height: 128px;
    overflow: hidden;
}
.inrow, .info_vertical .info_bonus_cards {
    float: left;
}
.info_vertical .info_bonus_cards, .info_vertical .img_cont_128 {
    margin: 0 auto;
}
.info_bonus_vertical {
    margin-left: 148px;
}
.info_bonus_text {
    line-height: 21px;
    max-width: 420px;
    margin: 0 auto; 
}
.info_bonus_text img {
    vertical-align: middle;
}
.info_bonus_text ul {
    list-style-type: disc;
    padding-left: 30px;
    text-align: left;
    overflow: hidden;
}
.info_bonus_text table,
.info_bonus_text li {
    color: #a36e35;
}
.info_bonus_text li {
    margin: 3px 0;
}
.info_comment {
    margin: 10px 0;
    color: #a36e35;
    font-size: 12px;
    text-align: center;
}
.info_header, .body_card_header, .info_section_header {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #044a7e;
    line-height: 36px;
    padding: 0 20px;
}
.body_card_header {
    padding-bottom: 40px;
}
.body_card_header .sub_header {
    font-size: 70%;
    opacity: 0.7;
}
.info_header {
    text-transform: uppercase;
}
.body_card_link {
    text-align: right;
    clear: both;
    width: 580px;
    margin: 0 auto;
    padding: 20px 0 0;
    font-weight: bold;
}
.body_card_link a {
    color: #9a7c5b !important;
}
.body_card_link a:after {
    content: " >";
}
.coffeecup, .businessmen {
    position: absolute;
    right: 40px;
}
.coffeecup {
    background: url(../images/coffee.png) no-repeat;
    width: 256px;
    height: 256px;
    bottom: 0;
}
.businessmen {
    background: url(../images/businessmen.png) no-repeat;
    width: 200px;
    height: 233px;
    bottom: 60px;
}
.body_maindiv {
    padding:40px 0;
    background: url(../images/bg-pergament.png) 50% 0 repeat;
    overflow: hidden;
}
.body_textsection, .info_section {
    max-width: 1160px;
    position: relative;
    margin:0 auto;
}
.info_section {
    padding: 40px 0;
}
.info_section .place_info {
    background: none;
    font-size: 24px;
}
.info_section .use-cont {
    font-size: 18px;
}
.info_section .bonus-effect {
    font-size: 18px;
}
.body_maintext, .info_maintext {
    padding: 0 40px;
    text-align:center;
    font-size:24px;
    line-height:32px;
    color:#3a2b2b;
}
.info_maintext {
    margin: 20px 0;
}
.info_middletext {
    padding: 20px;
    font-size:20px;
    line-height:26px;
    color:#3a2b2b;
}
.info_basetext {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    line-height:21px;
    color:#3a2b2b;
}
.info_basetext input[type=text] {
    width: 200px;
}
.info_maintext a,
.info_basetext a,
.info_bonus_text a,
.body_textsection a,
.body_maintext a {
    color: #044a7e;
}
.info_quote {
    color: #269a30;
    text-align: center;
    font-style: italic;
    padding: 0 20px 20px 20px;
}
.users {
    text-align: center;
    line-height: 24px;
}
.users span {
    color: #484540;
    font-weight: bold;
    margin-right: 10px;
}
.body_text {
    padding: 0 40px;
    line-height:21px;
    color:#3a2b2b;
    margin-bottom: 40px;
}
.body_text p {
    margin: 10px 0;
}
.body_maintext_title, .body_maintext_title_tm {
    font-weight: bold;
    color: #044a7e;
}
.body_maintext_title {
    text-transform: uppercase;
}
.body_maintext_title_tm:after {
    content: '\2122';
}
.body_footer {
    background: #444;
    border-top: 2px solid #333;
    text-align: center;
    min-height: 580px;
    position: relative;
    padding-top: 80px;
    line-height: 20px;
}
.body_footer_title {
    font-size:33px;
    font-family:Arial Black, arial;
    color:#eee;
    line-height:33px;
    margin: 40px 0;
}
.body_footer_social {
    width: 192px;
    margin: 0 auto;
}
.body_footer_social li {
    float: left;
}
.social32 {
    width: 32px;
    height: 32px;
    display: inline-block;
    background-image: url('../images/social.png');
}
.vkontakte {
    background-position: 0px 0px;
}
.vkontakte:hover {
    background-position: 0px -32px;
}
.facebook {
    background-position: -32px 0px;
}
.facebook:hover {
    background-position: -32px -32px;
}
.odnoklassniki {
    background-position: -64px 0px;
}
.odnoklassniki:hover {
    background-position: -64px -32px;
}
.moimir {
    background-position: -96px 0px;
}
.moimir:hover {
    background-position: -96px -32px;
}
.twitter {
    background-position: -128px 0px;
}
.twitter:hover {
    background-position: -128px -32px;
}
.youtube {
    background-position: -160px 0px;
}
.youtube:hover {
    background-position: -160px -32px;
}
.body_footer_links {
    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
    color: #aaa;
}
.body_footer_links a:hover {
    color: #fff;
}
.body_footer_links span {
    padding: 0 2px;
    color: #888;
}
.history_items {
    text-align:left;
    list-style-type:disc;
    margin-left:15px;
    padding: 0 20px;
}
.game_formparam {
    width: 280px;
    margin: 0 auto;
    overflow: hidden;
    color: #ac7748;
    font-size: 14px;
}
.game_join_container {
    overflow: hidden;
    width: 480px;
}
.game_join_vertical {
    margin-left: 155px;
    margin-right: 19px;
}
#gamejoin {
    min-height: 496px;
}
.game_join {
    padding: 10px;
}
.game_join dl,
.game_join_vertical dl {
    color: #a36e35;
    font-size: 12px;
}
.game_join dt,
.game_join_vertical dt {
    float: left;
    width: 173px;
}
.game_join dd,
.game_join_vertical dd {
    font-weight: bold;
    text-align: right;
}
.mygamelist dt, .mygamelist dd {
    line-height: 32px;
}
.mygamelist dt {
    width: 110px;
}
.game-select-desc {
    display: none;
    margin: 10px 20px;
}
.game-select-desc.selected {
    display: block;
}
.chars-attrs-params, .chars-attrs-skills {
    font-size: 15px;
    line-height: 21px;
    overflow: hidden;
}
.chars-attrs-skills {
    margin: 10px 0;
}
.chars-img-exp {
    width: 130px;
}
.chars-img-cont {
    border: 1px solid #ad781c;
}
.chars-exp {
    color: #a36e35;
    line-height: 21px;
}
.chars-exp p {
    margin: 5px 0;
}
.chars-attrs-cont {
    float: right;
    color: #a36e35;
    margin: 0 auto;
    padding: 0 5px;
}
.chars-attrs-cont dd {
    line-height: 23px;
    font-weight: bold;
    text-align: right;
}
.chars-attrs-cont dt {
    float:left;
    width: 115px;
}
.chars-attrs {
    float: left;
    width: 145px;
    margin-right: 10px;
}
.change-avatar-cont {
    margin-top: 10px;
}
.change-avatar {
}
.change-attrs-submit, .change-avatar-submit {
    font-weight: bold;
    color: #279a30;
}
.change-attrs-submit {
    margin-left: 15px;
}
.chars-games {
    float: right;
    width: 150px;
}
.body_smalltext {
    font-size: 10px;
}
.game_create_vertical {
    margin-left: 128px;
    margin-top: 40px;
}
.game__invite_cont {
    height: 405px;
    padding: 10px 0;
}
.mainmenu {
    margin:0 auto;
    width:620px;
    background:#eee;
    padding:6px;
    border:1px solid #aaa;
    border-top:1px solid #777;
    height:14px;
    position:relative;
}
.mainmenu a {
    text-decoration:none;
    margin:0 10px;
    color:#777;
}
.mainmenu a.selected {
    padding:6px 12px 6px;
    background-color:#ccc;
    border:1px solid #777;
    border-top:none;
}
.newchar-hint {
    top:22px;
    right:80px;
    background:#FFE4C4 !important;
    border:1px solid #aaa;
}
.newchar-hint .info_panel_pointer.left_corner {
    border-color:transparent #BBAB99 #BBAB99 transparent !important;
}
.newchar-hint .info_panel_pointer.right_corner {
    border-color:transparent transparent rgb(124, 110, 93) rgb(124, 110, 93) !important;
}
.newchar-hint p {
    text-align:center !important;
    color:#777;
}
.games {
	border-collapse:collapse;
    width:100%;
}
.games td, .games th {
	padding:3px;
	text-align:center;
}
.games th {
	padding:5px;
	font-weight:bold;
}
.games img {
    vertical-align: middle;
    margin-right: 10px;
}
.main_part {
	overflow:hidden;margin:0 10px 48px 10px;
    padding:10px;
    background: #ece1c6;
}
.color-pick {
    width:36px;
    height:36px;
    border:1px solid #444;
    margin:5px;
    float:left;
    cursor:pointer;
}
.color-pick.selected {
    border:3px solid #a44;
}
.color-pick-cont {
    overflow: hidden;
    width: 200px;
    margin: 0 auto;
}
.player-place {
    width:32px;
    height:32px;
    border-radius: 4px;
    margin:5px;
    float:left;
}
.player-name {
    line-height: 32px;
    text-align: center;
    font-weight: bold;
    color: #a36e35;
}
.img64.turn {
    background-image: url(../images/turn.png);
}
.turn .txt1,
.turn .txt2,
.turn .txt3,
.turn .txt4 {
    top: 20px;
    position: absolute;
    text-align: center;
    font-weight: bold;
    width: 100%;
}
.turn .txt1,
.turn .txt2 {
    line-height: 12px;
    font-size: 10px;
}
.turn .txt1 {
    color: #ff0;
}
.turn .txt2 {
    color: #777;
}
.turn .txt4 {
    top: 20px;
    color: #777;
    font-size: 8px;
    line-height: 8px;
}
.img64.dice {
    background-image: url(../images/dice.png);
}
.main_panel {
    border:1px solid #777;
    padding:10px;
}
.common_panel {
    margin:0px auto 50px auto;
    width:570px;
    background:tan;
    color:black;
}
.common_panel h2 {
    color:green;
    font-weight:bold;
    padding:10px;
}
#online {
    line-height: 24px;
}
#online img {
    vertical-align: middle;
}
#online span {
    color: #484540;
}
#online span._value {
    color: #24951b;
}
#online ._nowrap {
    margin-right: 10px;
    display: inline-block; 
}
.online {
    display:none;
    position:absolute;
    right:6px;
    bottom:7px;
    width:100px;
    color:#fff;
    padding-top:15px;
    text-align:right;
}
.border_radius {
	border:1px solid black;
	-webkit-border-radius:20px;
	-o-border-radius:20px;
	-moz-border-radius:20px;
}
#user_info {
    position:absolute;
    bottom:5px;
    left:5px;
    padding:5px;
    height:35px;
}
#user_avatar {
	margin: 3px 12px 3px 5px;
	height:32px;
	width:32px;
}
.user_label {
    cursor: pointer;
    color: #dab628;
    float: left;
    line-height: 36px;
    text-align: center;
    font-size: 16px;
}
.user_notifs {
    width: 32px;
    height: 32px;
    background-image: url(../images/bell.png);
	background-position: 0px 0px;
    position: relative;
    margin-top: 3px;
    margin-right: 12px;
    cursor: pointer;
}
.user_notifs.no {
	background-position: -32px 0px;
}
.user_notifs .cnt {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 12px;
    height: 12px;
    background-color: #e83737;
    border: 1px solid #deb722;
    border-radius: 6px;
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-size: 8px;
    line-height: 12px;
}
#menu_label {
    background: url(../images/menu24.png) 50% 50% no-repeat;
    cursor:pointer;
    width: 24px;
    height: 38px;
}
#user_actions {
    position:absolute;
    top:34px;
    right:5px;
    width:250px;
    background:#2e4f67;
    padding:10px;
    border:1px solid #072031;
    color: #dec79b;
    font-weight: bold;
    z-index:3000;
}
#user_actions hr {
    border: 1px solid #D4BA8C;
}
#user_actions a {
    display: block;
    padding: 10px;
}
#user_actions a.login {
    color: #dab628;
    text-shadow: 1px 1px 2px #0f324c;
}
#user_actions a:hover {
    background: #3d6786;
}
.main_header {
    position: relative;
    text-align: center;
    padding: 10px;
    width: 640px;
    margin: 10px auto 0;
    background: #ccc; /* Show a solid color for older browsers */
    background: -moz-linear-gradient(#1d1d1d, #ccc);
    background: -o-linear-gradient(#1d1d1d, #ccc);
    background: -webkit-linear-gradient(#1d1d1d, #ccc);
}
#user_chars {
	margin-top:5px;
	text-align:right;
	clear:both;
	float:right;
	display:none;
}
#user_chars h2 {
	font-weight:bold;
}
#user_chars p {
	margin:5px 0;
}
#images {
    /*
    width: 640px;*/
    max-width: 1160px;
    margin: 0 auto;
    height: 640px;
}
/* Image Transitions */
ul#images {
    padding: 0;
    list-style: none;
    position: relative;
}
ul#images li {
    padding: 0;
    overflow: hidden;
    /*display: block;
    float: left;
    width: 100px;
    border: 1px solid #5b4c1d;
    box-shadow: 1px 1px 3px 3px inset*/
    height: 600px;
}
.images-li-last {
    border-right: 10px solid #a08a43;
}
.kwicks-inner-div {
    min-width: 200px;
    width: 100%;
    height:100%;
    padding: 10px;
    background: #a08a43;
}
.kwicks-screen {
    width: 888px;
    height: 580px;
}
.kwicks-screen1 {
    background: url(../images/screens/screen1.jpg);
}
.kwicks-screen2 {
    background: url(../images/screens/screen2.jpg);
}
.kwicks-screen3 {
    background: url(../images/screens/screen3.jpg);
}
.kwicks-screen4 {
    background: url(../images/screens/screen4.jpg);
}
.kwicks-screen5 {
    background: url(../images/screens/screen5.jpg);
}
.kwicks-screen6 {
    background: url(../images/screens/screen6.jpg);
}
.news-window {
    background:#eee;
    width:600px;
    overflow:hidden;
    height:100%;
    padding:20px;
}
.news-window h1 {
    font-weight:bold;
    color:#555;
    margin:10px 0;
}
.news-window p {
    margin:10px 0;
}
.games-players {
    overflow: hidden;
    margin: 0 20px;
}
.current_games, .top_chars, .newsline, .main_chat, .fast_game {
    padding:20px;
}
.newsline {
}
.newsline li {
    margin:5px 0;
    color:#aaa;
}
.index-link {
    color:#269A30;
    font-weight:bold;
    padding:0 5px;
    text-decoration:none;
}
.main_chat {
    height:230px;
    width: 260px;
    float: right;
    overflow: hidden;
}
.current_games {
    height: 230px;
    width: 280px;
    float: left;
    overflow: hidden;   
}
.current_games_cont {
    overflow-y:auto;
    height:158px;
    padding:20px 0;
}
.online__game_item {
    margin: 5px 0px;
    background: #d4ba8c;
}
.online__game_title {
    float: left;
    margin: 5px 0px 5px 5px;
    height: 32px;
    line-height: 32px;
}
.fast_game {
    height:87px;
    text-align:center;
}
.fast_game a {
    text-decoration:none;
}
.top_chars {
    max-width: 450px;
    margin: 0 auto;
}
.main_screens {
    background:#ccc;
    padding:12px;
}
.chat_name {
    font-weight:bold;
    color:#555;
}
.chat_msg {
    margin-left:5px;
}
.chat_input {
    text-align:center;
    margin-top:10px;
}
.main_button_cont {
    text-align: center;
    margin: 20px 0;
}
.main_buttons {
    background:tan;
    width:200px;
    padding:10px;
    float:left;
}
.main_buttons p {
    text-align:center;
}
.main_buttons a {
    width:150px;
    margin:7px 0px;
}
.game_screens {
}
.form_data {
	margin:15px auto;
	padding:0 10px;
    overflow:hidden;
    width:300px;
}
.form_data dt {
	float:left;
	width:150px;
	font-weight:bold;
	vertical-align:middle;
	line-height:21px;
	font-size:14px;
    color:#ad781c;
}
.form_data dt:before {
    content: " ";
    clear: both;
}
.form_data dd {
    float:right;
    margin-left:10px;
}
.form_data dd:after {
    content: " ";
    clear: both;
}
.form_data dd p {
    text-align:right;
}
.form_data dd input {
    width:130px;
}
.form_data .form_data_field {
    overflow: hidden;
    clear: both;
}
#game_title {
    text-align: center;
}
.frinvite {
    display:none;
    position: relative;
}
.frinvite input {
    text-align:center;
}
.invite_field {
    width: 200px;
}
.friends_select {
    background: #dec79b;
    padding: 10px;
    border: 1px solid #a56e35;
    position: absolute;
    top: 25px;
    left: 50%;
    width: 180px;
    margin-left: -100px;
    z-index: 10;
}
.friends_select p {
    cursor: pointer;
}
.friends_select p.selected, .friends_select p:hover {
    background: #ece1c6;
}
.friends_select span.lighted {
    color: #ac7535;
}
.innovation_div {
	float:left;
	padding:1px;
	margin:5px;
	width:177px;
	height:200px;
	background:#eee;
	border:1px solid black;
	-moz-border-radius:20px;
	-webkit-border-radius:20px;
	border-radius:20px;
}
.innovation_title {
	width:160px;
	padding:5px;
	height:30px;
	border:3px solid gray;
	background:orange;
	position:relative;
	overflow:hidden;
	-moz-border-radius:20px;
	-webkit-border-radius:20px;
	border-radius:20px;
	-moz-box-shadow:0px 0px 10px 1px black inset;
	-webkit-box-shadow:0px 0px 10px 1px black inset;
	box-shadow:0px 0px 10px 1px black inset;
}
.innovation_title p, .innovation_item p {
	text-align: center;
    margin-right: 5px;
    font-weight: bold;
    font-size: 12px !important;
}
.innovation_toplite {
	height:500px;
	width:500px;
	-moz-border-radius:250px;
	-webkit-border-radius:250px;
	border-radius:250px;
	background:rgba(255,255,255,0.3);
	top:-480px;
	left:-165px;
	position:absolute;
}
.innovation_item, .skill_item {
	margin:5px;
	padding:5px;
    float:left;
}
.innovation_item {
	background:#ccc;
	border:1px solid gray;
    box-shadow:1px 1px 7px 1px black;
}
.skill_item {
    position: relative;
}
.skill_item p {
    font-size: 12px !important;
}
.skill_item.selected {
    background: #bbb;
}
.skill-type {
    position: absolute;
    z-index: 100;
    width: 138px;
    text-align: center;
    font-weight: bold;
    margin: 5px 0;
    margin-left: 4px;
    color: white;
    background: rgba(0,0,0,.5);
    padding: 1px;
}
.char-field {
    float:left;
    width:150px;
    font-weight:bold;
}
/************************** GAME MENU *****************************/
.menu-cont-item, .menu-cont-left, .menu-cont-item730 {
    float:left;
    position:relative;
}
.menu-cont-item, .menu-cont-item730 {
    padding-right:5px;
}
.menu-cont-right, .menu-cont-right730 {
    float:right;
    position:relative;
    padding-left: 5px;
}
.game_menu {
    font-size: 12px;
	background: black;
	line-height: 16px;
	color: white;
	padding: 5px 10px;
    height: 60px;
    position: relative;
    border-bottom: 1px solid #3a3a35;
}
.game_menu img {
	vertical-align:top;
}
.game_menu a {
}
.player_menu {
	float:left;
	text-align:left;
}
#char_cash {
    float:left;
    margin-right:10px;
}
#venture_empls {
    float:left;
    margin-right:10px;
}
.bottom_panels {
	position:absolute;
    /*background: rgba(79, 100, 107, 0.6);*/
	bottom:0;
	left:50%;
    height:150px;
}
.bottom_panels.player {
    margin-left:-621px;
}
.bottom_panels.guest {
    margin-left:-365px;
}
.bottom_panels__cont {
    padding: 5px;
}
.info {
    display: inline-block;
    background: url(../images/info.png);
    width: 16px;
    height: 16px;
    margin-left: 5px;
}
.cell_cont .info,
.cell_corner .info {
    position: absolute;
    cursor: pointer;
    top: 5px;
    right: 5px;
}
.notifs-cont {
    position:absolute;
    top: 20px;
    right:0px;
    padding:5px;
    display:block;
    width:34px;
    z-index:980;
}
.notify-desc {
    position:absolute;
    right:50px;
    top:-5px;
    z-index:99;
}
.notify-msg {
    font-size: 14px;
    padding:20px;
    width:235px;
    color:white;
    text-align:center;
    border:1px solid #4c4c2e;
    border-radius:4px;
    background:rgba(0,0,0,0.8);
}
.notify-msg a {
    text-decoration:none;
    color:lime;
}
.notify-msg h1 {
    color:#BFBF38;
    font-weight:bold;
    margin-bottom:5px;
}
.notify-msg h2 {
    color:orange;
    margin-bottom:5px;
}
.notify-msg li {
    text-align:left;
    padding-top: 10px;
    font-size: 12px;
    color: #ddd;
    font-weight: normal;
}
.notify-msg img {
    vertical-align:middle;
}
.map-button {
    position:absolute;
    cursor:pointer;
    right:3px;
    padding:5px;
    border:1px solid gray;
    display:block;
    background:#eee;
    z-index:100;
}
.img-cont {
    cursor:pointer;
}
.ventr-search {
    float:left;
    margin:3px 3px 0 0;
}
.img-resources {
    background:url(../images/showres16.png);
    width:16px;
    height:16px;
}
.searchimg32,
.zoomin32,
.zoomout32,
.autoturn32,,
.endturn32 {
    cursor: pointer;
    width:32px;
    height:32px;
    margin-left: auto;
    float: right;
}
.searchimg32 {
    background:url(../images/search32.png);
}
.zoomin32 {
    background:url(../images/zoomin32.png);
}
.zoomout32 {
    background:url(../images/zoomout32.png);
}
.endturn32 {
    background:url(../images/endturn32.png);
}
.autoturn32 {
    background:url(../images/autoturn32.png);
}
.searchimg16, .searchimg {
    background:url(../images/search16.png);
    width:16px;
    height:16px;
}
#player_levelup {
    display:none;
    border-radius:10px;
    box-shadow:0 0 10px 0 black;
    background:#ddd;
    padding:10px;
    width:320px;
    left:50%;
    margin-left:-160px;
    position:absolute;
    top:55px;
    z-index:1001;
}
.d-levelup {
    text-align: center;
}
#fskillup_cont {
    max-width:450px;
}
#fskillup_cont h1, #fskillup_cont p, #fskillup_cont h2 {
    text-align:center;
}
#fskillup_cont h1,
.d-levelup h1 {
    font-weight:bold;
    font-size:14px;
}
.d-levelup h2,
#fskillup_cont h2 {
    font-size: 12px;
    color: green;
}
#fskillup_cont p {
}
.d-levelup .attr_inc,
#fskillup_cont .attr_inc {
    font-size:11px;
    width: 140px;
    margin:10px auto 0px;
    text-align:left;
}
#fskillup_cont .attr_inc input {
    vertical-align: middle;
}
#fskillup_cont .attr_inc label {
    vertical-align: middle;
}
.top_info_panel_cont {
	position:absolute;
	border-radius:8px;
	padding:3px;
    width:250px;
	background:rgba(0,0,0,0.9);
	color:white;
    border: 1px solid #444;
}
.gameinfo dd, .info_value, .info_values p > span:last-child {
	color:#9ac019;
    font-weight:bold;
	display:inline-block;
	float:right;
}
.info_value, .info_values p > span:last-child sup {
	color:#9ac019;
}
.info_values p {
    text-align: left;
    font-style: normal;
    clear:both;
}
.info_values img {
    vertical-align: middle;
}
.top_info_panel_cont h2 {
	font-size:14px;
	font-weight:bold;
	margin:3px 0;
	font-family:verdana;
	text-align:center;
	color:steelBlue;
}
.top_info_panel_cont h3 {
	text-align:center;
	font-size:12px;
}
.top_info_panel_cont p {
	margin: 3px 0;
	text-align: left;
	font-size: 12px;
}
.info_panel_pointer {
	position:absolute;
	border:4px solid;
	font-size:0;
	overflow:hidden;
	zoom:1;
	height:0;
	top:-8px;
	width:0;
}
.info_panel_pointer.left_corner {
    border-color:transparent #777 #777 transparent;
}
.info_panel_pointer.right_corner {
    border-color:transparent transparent black black;
}
.info_panel_part {
	border-radius:7px;
    /*overflow:hidden;*/
	padding:5px;
	margin:3px;
}
.img-middle img, .info_panel_part img {
	line-height:10px;
	vertical-align:middle;
}
.info_panel_part a {
    text-decoration:none;
    color:rosybrown;
    cursor: pointer;
}
.info_panel_part li {
    line-height:16px;
    overflow:hidden;
    margin: 5px 0;
    font-size: 12px;
}
.info_panel_part h1 {
    font-weight: bold;
}
.menu-link {
    text-decoration:none;
    color:rosybrown !important;
    cursor: pointer;
}
.player_info {
	top:41px;
	left:0;
    width: 210px;
	z-index:1110;
}
.player_info .left_corner {
    left: 7px;
}
.player_info .right_corner {
    left: 15px;
}
.cash_cont {
    min-width: 65px;
}
.cash_info {
	top:25px;
	left:-50px;
	z-index:1109;
    width:290px;
}
.cash_info .left_corner {
    left: 50px;
}
.cash_info .right_corner {
    left: 58px;
}
.income_cont {
    min-width: 45px;
    color: yellow;
}
.income_info {
	top:25px;
	left:-90px;
	z-index:1111;
    width:250px;
}
.income_info .left_corner {
    left: 97px;
}
.income_info .right_corner {
    left: 105px;
}
.player_entrs {
    top:25px;
    left:-97px;
    width:300px;
    z-index:1108;
}
.player_entrs .left_corner {
    left: 96px;
}
.player_entrs .right_corner {
    left: 104px;
}
.player_factories {
    top:25px;
    left:-40px;
    width:290px;
    z-index:1107;
}
.player_factories .left_corner {
    left: 40px;
}
.player_factories .right_corner {
    left: 48px;
}
.player_empls {
    top:25px;
    z-index:1106;
    left:-80px;
}
.player_empls .left_corner {
    left: 81px;
}
.player_empls .right_corner {
    left: 89px;
}
.player_happy_empls {
    top:25px;
    z-index:1105;
    left:-140px;
    width: 270px;
}
.player_happy_empls .left_corner {
    left: 140px;
}
.player_happy_empls .right_corner {
    left: 148px;
}
.players_list {
    top:25px;
    z-index:1104;
    right:-10px;
}
.pl-list-col {
    overflow: hidden;
    text-align: center;
    float: left;
    width: 120px;
}
#effs_list_lb {
    color:lime;
}
.effects_list {
    top:25px;
    z-index:1121;
    right:-25px;
    width: 290px;
}
.effects_list .left_corner {
    right: 34px;
}
.effects_list .right_corner {
    right: 26px;
}
#effects_list_cont li {
    padding:5px;
}
.store_list {
    padding: 10px;
    top:25px;
    z-index:1120;
    left:-31px;
    width: auto;
}
.store_list .left_corner {
    left: 32px;
}
.store_list .right_corner {
    left: 40px; 
}
.store_list.store1 {
    left: -5px;
}
.store_list.store1 .left_corner {
    left: 6px;
}
.store_list.store1 .right_corner {
    left: 14px;
}
.store_list.store3 {
    left: -62px;
}
.store_list.store3 .left_corner {
    left: 64px;
}
.store_list.store3 .right_corner {
    left: 72px;
}
.store_list.store4 {
    left: -101px;
    min-width: 110px;
}
.store_list.store4 .left_corner {
    left: 103px;
}
.store_list.store4 .right_corner {
    left: 111px; 
}
.store_list.store5 {
    left: -85px;
    min-width: 110px;
}
.store_list.store5 .left_corner {
    left: 85px;
}
.store_list.store5 .right_corner {
    left: 93px; 
}
.bonus-effect {
    font-size: 10px;
    font-weight: bold;
    color: #a36e35;
}
.bonus-effect span {
    vertical-align: middle;
}
.last_moves {
    top:25px;
    z-index:1121;
    right:-17px;
}
.last_moves .left_corner {
    right: 48px;
}
.last_moves .right_corner {
    right: 40px;
}
.fortune-lb {
    top:25px;
    z-index:1103;
    right:-10px;
}
.menu_dialog {
    position:absolute;
	display:none;
    background:#FBFBFB;
    border:1px solid #777;
	padding:3px;
	margin-top: 5px;
	margin-left:-100px;
    left:50%;
	top:30px;
	z-index:1000;
    width:380px;
}
.loader {
    background: url(../images/loader.gif) no-repeat center center;
    position:absolute;
    width: 16px;
    height: 16px;
    top: 10px;
    left: 50%;
    margin-left: -8px;
    z-index: 10000;
}
.pl-effects {
    margin: 10px;
    color: #fff;
    text-align: center;
    background: rgba(0,0,0,0.7);
    padding: 10px;
}
.pl-effects li {
    margin: 10px 0;
}
#main_menu, #pl_effects {
    display: inline-block;
    width: 16px;
    height: 16px;
}
#main_menu:after {
    content: url(../images/menu16.png);
}
#pl_effects:after {
    content: url(../images/effects.png);
}
#pl_effects {
    cursor: pointer;
}
#char-img {
    text-align: center;
    cursor: pointer;
}
#resources {
    cursor: pointer;
    margin-left: 5px;
}
.main_menu_cont {
	top:25px;
	right:-5px;
	width:190px;
	z-index:1111;
}
.main_menu_cont .left_corner {
    right: 12px;
}
.main_menu_cont .right_corner {
    right: 4px;
}
.main_menu_cont a {
	color: white;
    line-height: 32px;
}
.main_menu_cont .info_panel_part div {
    line-height: 32px;
}
.main_menu {
    padding: 10px;
    color: #fff;
    background: #777;
    border-radius: 4px;
    border: 1px solid #aaa;
    text-shadow: 0px 1px 1px #000;
}
.main_menu li {
    margin: 10px;
    cursor: pointer;
}
.main_menu li a {
    display: block;
}
.big_digit {
    font-weight: 700;
    font-size: 48px;
    text-shadow: 0 1px 2px #6b6d0b;
    text-align: center;
    line-height: 48px;
    color: #fed350;
}
.big_digit.gray {
    color: #ccc;
    text-shadow: none;
}

.food-bg {
    background: url(../images/food.jpg) no-repeat;
}
.metal-bg {
    background: url(../images/metal.jpg) no-repeat;
}
.oil-bg {
    background: url(../images/oil.jpg) no-repeat;
}
.textile-bg {
    background: url(../images/textile.jpg) no-repeat;
}
.chips-bg {
    background: url(../images/chips.jpg) no-repeat;
}
.st-used-cnt {
    display:inline-block;
    padding-top:3px;
}
.commontext-cont {
}
.action-hint, .commontext__cont p {
    font-size:12px;
    padding:5px !important;
    margin:0 5px;
    line-height: 16px;
}
.action-hint img,
.commontext__cont p img {
    vertical-align: middle;
}
.venture__detail {
    font-weight: bold;
    float: right;
}
.cell_logo {
	margin:12px auto;
	display:block;
	width:40px;
}
.cell_entrs {
    margin: 0 10px;
}
.cell_level {
    position:absolute;
    left:1px;
    bottom:1px;
    width:144px;
    border:1px solid #928c78;
    height:17px;
    border-radius: 6px;
}
.cell-plc-prc-cont {
    position:absolute;
    width:100%;
}
.cell-plc-prc {
    background:rgba(100,215,20,0.5);
    float:left;
    height:17px;
}
.cell-plc-prc-next {
    background:rgba(145,242,79,0.5);
    float:left;
    height:17px;
}
/************ FACTORY *******************/
.fact {
    padding: 10px;
    background: rgba(255, 255, 255, 0.9);
}
.fact p {
    margin: 5px 0;
}
.fact-d {
    text-align: right;
    font-weight: bold;
    color: #777;
}
.fact-empls {
    clear: both;
}
.fact-empls-free {
    float:left;
    position:relative;
    height:215px;
    width: 180px;
}
.fact-empls-free .hint {
    position:absolute;
    color:#888;
    font-size:12px;
    width:100px;
    top:40px;
    left:50%;
    margin-left:-50px;
    text-align:center;
    z-index:1;
}
.fact-empls-cont {
    position:absolute;
    width:155px;
    height:170px;
    border:1px solid #ccc;
    padding:5px;
    overflow:auto;
    z-index:2;
    margin-left:2px;
    cursor: pointer;
}
.factory_stat {
    margin:0 auto;
}
.factory_stat th {
    color:brown;
    font-weight:bold;
}
.factory_stat td, .factory_stat th {
    text-align:center;
    padding:3px;
    border:1px solid gray;
}
.factory_stat img {
    vertical-align:middle;
}
.factory_stat p {
    padding:3px;
}
.res-bg {
    border: 1px solid #777;
    background: #e1f3b2;
}
.res-yield {
    width: 280px;
    margin: 10px 0;
    padding: 10px;
    text-align: center;
}
.res-yield p {
    margin-top: 5px;
}
.res-bar-cont {
    overflow: hidden;
    margin: 0 auto;
    width: 234px;
}
.res-bar {
    float: left;
    border: 1px solid #6ba265;
    width: 206px;
    margin-left: 3px;
}
.res-bar-sl {
    background: #b2d065;
    height: 20px;
    line-height: 20px;
    vertical-align: middle;
}
/****************************************/
.outer_circle {
    -moz-border-radius:43px;
    -webkit-border-radius:43px;
    border-radius:43px;
    margin:0 auto;
    border:1px solid gray;
    height:86px;
    position:relative;
    overflow:hidden;
    width:86px;
}
.lite_circle {
    -moz-border-radius: 45px;
/*    background-color: white;*/
    height: 90px;
    left: -15px;
    opacity: 0.3;
    position: absolute;
    top: -40px;
    width: 90px;
}
.inner_circle {
    -moz-border-radius: 35px;
	-webkit-border-radius: 35px;
	border-radius: 35px;
	-moz-box-shadow:0 -3px 0px -1px inset;
	-webkit-box-shadow:0 -3px 0px -1px inset;
	box-shadow:0 -3px 0px -1px inset;
	background-color: #bbb;
	border:1px solid gray;
    height: 70px;
    left: 7px;
    position: absolute;
    top: 7px;
    width: 70px;
}
.minus, .plus {
	text-decoration:none;
	text-shadow:0px 2px 3px gray;
    font-size: 11px;
    line-height: 21px;
    vertical-align: top;
}
.char_attr {
	font-weight:bold;
}
.treasure, .arrow, .img32 {
    height:32px;
    width:32px;
}
.treasure {
    bottom:5px;
    position:absolute;
    left:11px;
}
.arrow-cont {
    margin: 0 5px;
    width: 32px;
}
.ml42 {
    margin-left: 52px;
}
.arrow {
	background-image:url(../images/arrows.png);
}
.arrow-right {
	background-position:-64px 0px;
}
.arrow-left {
	background-position:-32px 0px;
}
.arrow-down {
	background-position:0px 0px;
    margin: 5px 0;
}
.arrow-down.right {
    margin-left: calc(100% - 80px);
}
.arrow-down.left {
    margin-left: 48px;
}
.tree {
    width: 1810px;
}
.tree-item {
    width: 320px;
    height: 220px;
    border: 1px solid #000;
    clear: both;
    margin: 10px;
    background: #ccc;
    cursor: pointer;
}
.tree-item.selected {
    box-shadow: 0px 0px 10px 0px #222;
}
.enterprise {
}
.enterprise h1 {
    font-size:1.3em;
    font-weight:bold;
    text-align:center;
}
.enterprise h2 {
    font-weight:bold;
}
.enterprise p {
    padding:2px 0;
}
.e_a {
    background-color: #8adf8d;
}
/* ----------- ATTRS TYPES ----------- */
.attrs {
    background-image:url('../images/attrs.png');
}
.intel {
    background-position: 0px 0px;
}
.know {
    background-position: -16px 0px;
}
.charm {
    background-position: -32px 0px;
}
.activ {
    background-position: -48px 0px;
}
.init {
    background-position: -64px 0px;
}
/* -------------- EFFECTS ------------- */
.img16.effects {
    background-image:url('../images/effects16.png');
}
/* ----------- SKILLS TYPES ----------- */
.pl-skills {
    width: 310px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sk-title {
    overflow: hidden;
}
.skills32_1 {
    width:32px;
    height:32px;
    margin:0 auto;
    background-image:url('../images/skills32_1.png');
}
.skills32_2 {
    width:32px;
    height:32px;
    margin:0 auto;
    background-image:url('../images/skills32_2.png');
}
.skills32_3 {
    width:32px;
    height:32px;
    margin:0 auto;
    background-image:url('../images/skills32_3.png');
}
.skill1 {background-position:0px 0px;}
.skill2 {background-position:-32px 0px;}
.skill3 {background-position:-64px 0px;}
.skill4 {background-position:-96px 0px;}
.skill5 {background-position:-128px 0px;}
.skill6 {background-position:0px -32px;}
.skill7 {background-position:-32px -32px;}
.skill8 {background-position:-64px -32px;}
.skill9 {background-position:-96px -32px;}
.skill10 {background-position:-128px -32px;}
.skill11 {background-position:0px -64px;}
.skill12 {background-position:-32px -64px;}
.skill13 {background-position:-64px -64px;}
.skill14 {background-position:-96px -64px;}
.skill15 {background-position:-128px -64px;}
.skill16 {background-position:0px -96px;}
.skill17 {background-position:-32px -96px;}
.skill18 {background-position:-64px -96px;}
.skill19 {background-position:-96px -96px;}
.skill20 {background-position:-128px -96px;}
.skills64_1 {
    background-image:url('../images/skills64_1.png');
}
.skills64_2 {
    background-image:url('../images/skills64_2.png');
}
.skills64_3 {
    background-image:url('../images/skills64_3.png');
}
.skill1_64 {background-position:0px -0px;}
.skill2_64 {background-position:-64px -0px;}
.skill3_64 {background-position:-128px -0px;}
.skill4_64 {background-position:-192px -0px;}
.skill5_64 {background-position:-256px -0px;}
.skill6_64 {background-position:0px -64px;}
.skill7_64 {background-position:-64px -64px;}
.skill8_64 {background-position:-128px -64px;}
.skill9_64 {background-position:-192px -64px;}
.skill10_64 {background-position:-256px -64px;}
.skill11_64 {background-position:0px -128px;}
.skill12_64 {background-position:-64px -128px;}
.skill13_64 {background-position:-128px -128px;}
.skill14_64 {background-position:-192px -128px;}
.skill15_64 {background-position:-256px -128px;}
.skill16_64 {background-position:0px -192px;}
.skill17_64 {background-position:-64px -192px;}
/* --------- SPRITES ---------- */
.img64 {
    position: relative;
    width: 64px;
    height: 64px;
}
.img24 {
    width:24px;
    height:24px;
}
.img16 {
    width:16px;
    height:16px;
}
/************** SPRITE **************/
.i16_1 {background-position:0px 0px;}
.i16_2 {background-position:-16px 0px;}
.i16_3 {background-position:-32px 0px;}
.i16_4 {background-position:-48px 0px;}
.i16_5 {background-position:-64px 0px;}
.i16_6 {background-position:-80px 0px;}
.i16_7 {background-position:-96px 0px;}
.i16_8 {background-position:-112px 0px;}
.i16_9 {background-position:-128px 0px;}
.i16_10 {background-position:-144px 0px;}
.i16_11 {background-position:0px -16px;}
.i16_12 {background-position:-16px -16px;}
.i16_13 {background-position:-32px -16px;}
.i16_14 {background-position:-48px -16px;}
.i16_15 {background-position:-64px -16px;}
.i16_16 {background-position:-80px -16px;}
.i16_17 {background-position:-96px -16px;}
.i16_18 {background-position:-112px -16px;}
.i16_19 {background-position:-128px -16px;}
.i16_20 {background-position:-144px -16px;}
.i16_21 {background-position:0px -32px;}
.i16_22 {background-position:-16px -32px;}
.i16_23 {background-position:-32px -32px;}
.i16_24 {background-position:-48px -32px;}
.i16_25 {background-position:-64px -32px;}
.i16_26 {background-position:-80px -32px;}
.i16_27 {background-position:-96px -32px;}
.i16_28 {background-position:-112px -32px;}
.i16_29 {background-position:-128px -32px;}
.i16_30 {background-position:-144px -32px;}
.i16_31 {background-position:0px -48px;}
.i16_32 {background-position:-16px -48px;}
.i16_33 {background-position:-32px -48px;}
.i16_34 {background-position:-48px -48px;}
.i16_35 {background-position:-64px -48px;}
.i16_36 {background-position:-80px -48px;}
.i16_37 {background-position:-96px -48px;}
.i16_38 {background-position:-112px -48px;}
.i16_39 {background-position:-128px -48px;}
.i16_40 {background-position:-144px -48px;}
.i16_41 {background-position:0px -64px;}
.i16_42 {background-position:-16px -64px;}
.i16_43 {background-position:-32px -64px;}
.i16_44 {background-position:-48px -64px;}
.i16_45 {background-position:-64px -64px;}
.i16_46 {background-position:-80px -64px;}
.i16_47 {background-position:-96px -64px;}
.i16_48 {background-position:-112px -64px;}
.i16_49 {background-position:-128px -64px;}
.i16_50 {background-position:-144px -64px;}
.i16_51 {background-position:0px -80px;}
.i16_52 {background-position:-16px -80px;}
.i16_53 {background-position:-32px -80px;}
.i16_54 {background-position:-48px -80px;}
.i16_55 {background-position:-64px -80px;}
.i16_56 {background-position:-80px -80px;}
.i16_57 {background-position:-96px -80px;}
.i16_58 {background-position:-112px -80px;}
.i16_59 {background-position:-128px -80px;}
.i16_60 {background-position:-144px -80px;}
.i16_61 {background-position:0px -96px;}
.i16_62 {background-position:-16px -96px;}
.i16_63 {background-position:-32px -96px;}
.i16_64 {background-position:-48px -96px;}
.i16_65 {background-position:-64px -96px;}
.i16_66 {background-position:-80px -96px;}
.i16_67 {background-position:-96px -96px;}
.i16_68 {background-position:-112px -96px;}
.i16_69 {background-position:-128px -96px;}
.i16_70 {background-position:-144px -96px;}
.i16_71 {background-position:0px -112px;}
.i16_72 {background-position:-16px -112px;}
.i16_73 {background-position:-32px -112px;}
.i16_74 {background-position:-48px -112px;}
.i16_75 {background-position:-64px -112px;}
.i16_76 {background-position:-80px -112px;}
.i16_77 {background-position:-96px -112px;}
.i16_78 {background-position:-112px -112px;}
.i16_79 {background-position:-128px -112px;}
.i16_80 {background-position:-144px -112px;}
.i16_81 {background-position:0px -128px;}

.i24_1 {background-position:0px 0px;}
.i24_2 {background-position:-24px 0px;}
.i24_3 {background-position:-48px 0px;}
.i24_4 {background-position:-72px 0px;}
.i24_5 {background-position:-96px 0px;}
.i24_6 {background-position:-120px 0px;}
.i24_7 {background-position:-144px 0px;}
.i24_8 {background-position:-168px 0px;}
.i24_9 {background-position:-192px 0px;}
.i24_10 {background-position:-216px 0px;}
.i24_11 {background-position:0px -24px;}
.i24_12 {background-position:-24px -24px;}
.i24_13 {background-position:-48px -24px;}
.i24_14 {background-position:-72px -24px;}
.i24_15 {background-position:-96px -24px;}
.i24_16 {background-position:-120px -24px;}
.i24_17 {background-position:-144px -24px;}
.i24_18 {background-position:-168px -24px;}
.i24_19 {background-position:-192px -24px;}
.i24_20 {background-position:-216px -24px;}
.i24_21 {background-position:0px -48px;}
.i24_22 {background-position:-24px -48px;}
.i24_23 {background-position:-48px -48px;}
.i24_24 {background-position:-72px -48px;}
.i24_25 {background-position:-96px -48px;}
.i24_26 {background-position:-120px -48px;}
.i24_27 {background-position:-144px -48px;}
.i24_28 {background-position:-168px -48px;}
.i24_29 {background-position:-192px -48px;}
.i24_30 {background-position:-216px -48px;}
.i24_31 {background-position:0px -72px;}

.i32_1 {background-position:0px 0px;}
.i32_2 {background-position:-32px 0px;}
.i32_3 {background-position:-64px 0px;}
.i32_4 {background-position:-96px 0px;}
.i32_5 {background-position:-128px 0px;}
.i32_6 {background-position:-160px 0px;}
.i32_7 {background-position:-192px 0px;}
.i32_8 {background-position:-224px 0px;}
.i32_9 {background-position:-256px 0px;}
.i32_10 {background-position:-288px 0px;}
.i32_11 {background-position:0px -32px;}
.i32_12 {background-position:-32px -32px;}
.i32_13 {background-position:-64px -32px;}
.i32_14 {background-position:-96px -32px;}
.i32_15 {background-position:-128px -32px;}
.i32_16 {background-position:-160px -32px;}
.i32_17 {background-position:-192px -32px;}
.i32_18 {background-position:-224px -32px;}
.i32_19 {background-position:-256px -32px;}
.i32_20 {background-position:-288px -32px;}
.i32_21 {background-position:0px -64px;}
.i32_22 {background-position:-32px -64px;}
.i32_23 {background-position:-64px -64px;}
.i32_24 {background-position:-96px -64px;}
.i32_25 {background-position:-128px -64px;}
.i32_26 {background-position:-160px -64px;}
.i32_27 {background-position:-192px -64px;}
.i32_28 {background-position:-224px -64px;}
.i32_29 {background-position:-256px -64px;}
.i32_30 {background-position:-288px -64px;}
.i32_31 {background-position:0px -96px;}
.i32_32 {background-position:-32px -96px;}
.i32_33 {background-position:-64px -96px;}
.i32_34 {background-position:-96px -96px;}
.i32_35 {background-position:-128px -96px;}
.i32_36 {background-position:-160px -96px;}
.i32_37 {background-position:-192px -96px;}
.i32_38 {background-position:-224px -96px;}
.i32_39 {background-position:-256px -96px;}
.i32_40 {background-position:-288px -96px;}
.i32_41 {background-position:0px -128px;}
.i32_42 {background-position:-32px -128px;}
.i32_43 {background-position:-64px -128px;}
.i32_44 {background-position:-96px -128px;}
.i32_45 {background-position:-128px -128px;}
.i32_46 {background-position:-160px -128px;}
.i32_47 {background-position:-192px -128px;}
.i32_48 {background-position:-224px -128px;}
.i32_49 {background-position:-256px -128px;}
.i32_50 {background-position:-288px -128px;}
.i32_51 {background-position:0px -160px;}
.i32_52 {background-position:-32px -160px;}
.i32_53 {background-position:-64px -160px;}
.i32_54 {background-position:-96px -160px;}
.i32_55 {background-position:-128px -160px;}
.i32_56 {background-position:-160px -160px;}
.i32_57 {background-position:-192px -160px;}
.i32_58 {background-position:-224px -160px;}
.i32_59 {background-position:-256px -160px;}
.i32_60 {background-position:-288px -160px;}
.i32_61 {background-position:0px -192px;}
.i32_62 {background-position:-32px -192px;}
.i32_63 {background-position:-64px -192px;}
.i32_64 {background-position:-96px -192px;}
.i32_65 {background-position:-128px -192px;}
.i32_66 {background-position:-160px -192px;}
.i32_67 {background-position:-192px -192px;}
.i32_68 {background-position:-224px -192px;}
.i32_69 {background-position:-256px -192px;}
.i32_70 {background-position:-288px -192px;}
.i32_71 {background-position:0px -224px;}
.i32_72 {background-position:-32px -224px;}
.i32_73 {background-position:-64px -224px;}
.i32_74 {background-position:-96px -224px;}
.i32_75 {background-position:-128px -224px;}
.i32_76 {background-position:-160px -224px;}
.i32_77 {background-position:-192px -224px;}
.i32_78 {background-position:-224px -224px;}
.i32_79 {background-position:-256px -224px;}
.i32_80 {background-position:-288px -224px;}
.i32_81 {background-position:0px -256px;}

.b9_168,
.i64_1 {background-position:0px 0px;}
.b9_169,
.i64_2 {background-position:-64px 0px;}
.b9_170,
.i64_3 {background-position:-128px 0px;}
.b9_171,
.i64_4 {background-position:-192px 0px;}
.b9_172,
.i64_5 {background-position:-256px 0px;}
.b9_173,
.i64_6 {background-position:-320px 0px;}
.b9_174,
.i64_7 {background-position:-384px 0px;}
.b9_214,
.i64_8 {background-position:-448px 0px;}
.b9_175,
.i64_9 {background-position:-512px 0px;}
.b9_176,
.i64_10 {background-position:-576px 0px;}
.b9_177,
.b9_219,
.b9_220,
.i64_11 {background-position:0px -64px;}
.b9_178,
.i64_12 {background-position:-64px -64px;}
.b9_179,
.i64_13 {background-position:-128px -64px;}
.b9_180,
.i64_14 {background-position:-192px -64px;}
.b9_181,
.i64_15 {background-position:-256px -64px;}
.b9_182,
.i64_16 {background-position:-320px -64px;}
.b9_183,
.i64_17 {background-position:-384px -64px;}
.b9_184,
.i64_18 {background-position:-448px -64px;}
.b9_185,
.i64_19 {background-position:-512px -64px;}
.b9_186,
.i64_20 {background-position:-576px -64px;}
.b9_188,
.i64_21 {background-position:0px -128px;}
.b9_215,
.i64_22 {background-position:-64px -128px;}
.b9_153,
.b9_217,
.b9_233,
.b9_234,
.i64_23 {background-position:-128px -128px;}
.i64_24 {background-position:-192px -128px;}
.b9_218,
.i64_25 {background-position:-256px -128px;}
.b9_222,
.b9_235,
.i64_26 {background-position:-320px -128px;}
.b9_236,
.b9_237,
.b9_238,
.i64_27 {background-position:-384px -128px;}
.i64_28 {background-position:-448px -128px;}
.i64_29 {background-position:-512px -128px;}
.i64_30 {background-position:-576px -128px;}
.b9_138,
.i64_31 {background-position:0px -192px;}
.b9_139,
.i64_32 {background-position:-64px -192px;}
.b9_140,
.i64_33 {background-position:-128px -192px;}
.i64_41 {background-position:0px -256px;}
.i64_42 {background-position:-64px -256px;}
.i64_43 {background-position:-128px -256px;}
/* ---------- ACTIONS TYPES ---------- */
.action-comp {
    float: right;
    cursor: pointer;
}
.action-comp .action {
    margin: 2px;
}
.img24.action, .img24.main__action {
    background-image:url('../images/acts24.png');
    text-align: right;
}
.img24.main__action {
    display: inline-block;
    vertical-align: middle;
    margin: 0 3px;
}
/* MENU */
.img32.menu {
    background-image: url('../images/menu.png');
}
/* ---------- NOTIFY TYPES ---------- */
.img32.notify {
    background-image:url('../images/notify.png');
    cursor:pointer;
    position:relative;
}
/* ------- SPECIALITY TYPES --------- */
.img32.spec {
    background-image:url('../images/spec32.png');
}
.img16.spec {
    background-image:url('../images/spec16.png');
}
/* --------- FACTORY TYPES ---------- */
.resbar32 {
    font-size: 18px;
    color: #29e215;
    font-weight: bold;
    text-shadow: 1px 1px 0px #000;
}
.resbar32 .muted {
    font-weight: normal;
    text-shadow: none;
}
.img32.factory {
    background-image:url('../images/resources32.png');
}
.img16.factory {
    background-image:url('../images/resources16.png');
}
/* ---------- MARKET TYPES ---------- */
.img64.resources64 {
    background-image:url('../images/resources64.png');
}
.resources64_1 {background-position:0px 0px;}
.resources64_2 {background-position:-64px 0px;}
.resources64_3 {background-position:-128px 0px;}
.resources64_4 {background-position:-192px 0px;}
.resources64_5 {background-position:-256px 0px;}
.resources64_6 {background-position:-320px 0px;}
/* ---------- OFFICE TYPES ---------- */
.img32.office {
    background-image:url('../images/office32.png');
}
.img16.office {
    background-image:url('../images/office16.png');
}
/* -------------- CELLS ------------- */
.cells_128 {
    background-image:url('../images/cells128.png');
}
/* ----------- INFO HOWTO ----------- */
.info_bonus_cards {
    background-image:url('../images/howto.png');
    /*background: url(../images/howto.png) 50% 0 repeat;*/
}
.cells1, .infobonus1 {
    background-position:0 0;
}
.cells2, .infobonus2 {
    background-position:-128px 0;
}
.cells3, .infobonus3 {
    background-position:-256px 0;
}
.cells4, .infobonus4 {
    background-position:-384px 0;
}
.cells5, .infobonus5 {
    background-position:0 -128px;
}
.cells6, .infobonus6 {
    background-position:-128px -128px;
}
.cells7, .infobonus7 {
    background-position:-256px -128px;
}
.cells8, .infobonus8 {
    background-position:-384px -128px;
}
.cells9, .infobonus9 {
    background-position:0 -256px;
}
.cells10, .infobonus10 {
    background-position:-128px -256px;
}
.cells11, .infobonus11 {
    background-position:-256px -256px;
}
.cells12, .infobonus12 {
    background-position:-384px -256px;
}
.cells13, .infobonus13 {
    background-position:0 -384px;
}
.cells14, .infobonus14 {
    background-position:-128px -384px;
}
.cells15, .infobonus15 {
    background-position:-256px -384px;
}
.cells16, .infobonus16 {
    background-position:-384px -384px;
}
.cells17, .infobonus17 {
    background-position:0 -512px;
}
.cells18, .infobonus18 {
    background-position:-128px -512px;
}
.cells19, .infobonus19 {
    background-position:-256px -512px;
}
.cells20, .infobonus20 {
    background-position:-384px -512px;
}
.cells21, .infobonus21 {
    background-position:0 -640px;
}
/* ----------- CARDS TYPES ---------- */
.cards_cont {
    position: absolute;
    top: -170px;
    left: -10px;
    overflow: hidden;
    margin: 1px;
    box-shadow: 0px 0px 5px;
    z-index: 10;
}
.cards {
    overflow: hidden;
    font-size: 12px;
    height:180px;
    width:148px;
    text-align:center;
    background-image:url('../images/cards.png');
}
.cards6 {
    background-position:0 0;
}
.cards3 {
    background-position:-148px 0;
}
.cards4 {
    background-position:-296px 0;
}
.cards5 {
    background-position:-444px 0;
}
/* insurance cards by bonus_id
 * cards9 - card for inventory
 */
.cards138 {
    background-position:-592px 0;
}
.cards139 {
    background-position:-740px 0;
}
.cards9, .cards140 {
    background-position:-888px 0;
}
.card-image {
    margin: 5px auto;
}
.card-text {
    color: black;
    padding: 0 10px;
    height: 34px;
    line-height: 1em;
    overflow-y: auto;
    font-weight: bold;
}
.card-effs {
    height: 55px;
    line-height: 1em;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 10px auto;
    width: 133px;
}
.card-effs li {
    width: 124px;
    padding: 3px 5px;
    margin: 0;
    float: none;
    box-shadow: none;
}
.card-effs6 {
    color: #454545;
}
.card-effs3 {
    color: #906d31;
}
.card-effs4 {
    color: #845f1e;
}
.card-effs5 {
    color: #8a505d;
}
/* insurance cards by key [ins1, ins2, ins3]
 * card-effs9 - effs color for inventory
 */
.card-effsins1 {
    color: #7b2525;
}
.card-effsins2 {
    color: #2f5573;
}
.card-effs9, .card-effsins3 {
    color: #316f31;
}
.card-label {
    position: absolute;
    top: 5px;
    width: 90%;
    padding: 5px 0;
    background: rgba(0,0,0,0.5);
    text-align: center;
    right: 7px;
    color: #fed350;
    font-weight: bold;
}
.inventory_cnt, .res64_cnt, .res64_fact {
    position: absolute;
    line-height: 24px;
    vertical-align: middle;
    text-align: center;
    font-weight: bold;
    border-radius: 12px;
    height: 24px;
    width: 24px;
}
.inventory_cnt {
    top: 5px;
    right: 5px;
    background: #f3f71e;
    font-size: 16px;
    box-shadow: 0px 1px 2px;
    border: 1px solid #333;
}
.res64_cnt {
    right: 8px;
    bottom: 8px;
    cursor: pointer;
    color: #a36e35;
    background: #fed350;
    font-size: 12px;
    box-shadow: 0px 1px 3px black;
}
.disabled {
    position: absolute;
    bottom: 8px;
}
.res64_lock {
    position: absolute;
    left: -10px;
    top: 6px;
    z-index: 10;
}
.res64_fact {
    position: absolute;
    right: -15px;
    top: 10px;
    background: #dcc16d;
    box-shadow: 0px 1px 3px black;
    font-size: 12px;
    color: #8c5b26;
    z-index: 10;
}
.fortune-image, .b3 {
    background-image:url('../images/fortune.png');
}
.fortune-image36, .b3_36 {background-position:0px -0px;}
.fortune-image137, .b3_137 {background-position:-64px -0px;}
.fortune-image4, .b3_4 {background-position:-128px -0px;}
.fortune-image47, .b3_47 {background-position:-192px -0px;}
.fortune-image49, .b3_49 {background-position:-256px -0px;}
.fortune-image48, .b3_48 {background-position:0px -64px;}
.fortune-image50, .b3_50 {background-position:-64px -64px;}
.fortune-image118, .b3_118 {background-position:-128px -64px;}
.fortune-image120, .b3_120 {background-position:-192px -64px;}
.fortune-image117, .b3_117 {background-position:-256px -64px;}
.fortune-image116, .b3_116 {background-position:0px -128px;}
.fortune-image119, .b3_119 {background-position:-64px -128px;}
.fortune-image121, .b3_121 {background-position:-128px -128px;}
.fortune-image128, .b3_128 {background-position:-192px -128px;}
/*.fortune-image {background-position:-256px -128px;}*/
.suprise-image, .b5 {
    background-image:url('../images/suprise.png');
}
.suprise-image2, .b5_129 {background-position:0px -0px;}
.suprise-image3, .b5_131 {background-position:-64px -0px;}
.suprise-image40, .b5_135 {background-position:-128px -0px;}
.suprise-image41, .b5_3 {background-position:-192px -0px;}
.suprise-image46, .b5_99 {background-position:-256px -0px;}
.suprise-image68, .b5_40 {background-position:0px -64px;}
.suprise-image85, .b5_123 {background-position:-64px -64px;}
.suprise-image99, .b5_2 {background-position:-128px -64px;}
.suprise-image123, .b5_41 {background-position:-192px -64px;}
.suprise-image129, .b5_85 {background-position:-256px -64px;}
.suprise-image131, .b5_68 {background-position:0px -128px;}
.suprise-image135, .b5_46 {background-position:-64px -128px;}
.message-image, .b4 {
    background-image:url('../images/message.png');
}
.message-image1, .b4_1 {background-position:0px -0px;}
.message-image38, .b4_38 {background-position:-64px -0px;}
.message-image39, .b4_39 {background-position:-128px -0px;}
.message-image42, .b4_42 {background-position:-192px -0px;}
.message-image43, .b4_67 {background-position:-256px -0px;}
.message-image44, .b4_44 {background-position:0px -64px;}
.message-image45, .b4_95 {background-position:-64px -64px;}
.message-image60, .b4_43 {background-position:-128px -64px;}
.message-image67, .b4_136 {background-position:-192px -64px;}
.message-image94, .b4_94 {background-position:-256px -64px;}
.message-image95, .b4_60 {background-position:0px -128px;}
.message-image122, .b4_127 {background-position:-64px -128px;}
.message-image127, .b4_45 {background-position:-128px -128px;}
.message-image136, .b4_122 {background-position:-192px -128px;}
/* ----------- INVENTORY ----------- */
.b9 {
    background-image:url('../images/jur.png');
}
/* ----------- ENTR TYPES ----------- */
.empl-item,
.entr-inline {
    float: left;
    cursor: pointer;
}
.img64.entrs {
    background-image:url('../images/entrs64.png');
    display: block;
}
.img24.entrs {
    background-image:url('../images/entrs24.png');
}
.img24.entrs.luxery {
    background-image:url('../images/entrs24lux.png');
}
.entr-level {
    float:left;
    /*
    overflow:hidden;
    position:relative;
    */
    padding:3px 10px 3px 10px;
    height:56px;
    width:680px;
}
.entr-level-arr {
    position:relative;
    height:50px;
    clear:left;
}
.entr-arr-img {
    position:absolute;
    width:100%;
    margin-left:12px;
}
.entr-level-ul {
    text-align:center;
    clear:left;
    float:left;
    position:relative;
    left:50%;
}
.entr_item {
    display:block;
    right:50%;
    position:relative;
    float:left;
    width:60px;
    height:60px;
    margin:0 3px;
}
.entr-img {
    border:1px solid #aaa;
    background:#eee;
    width:100%;
    height:48px;
}
.entr-bonus {
    margin: 5px 0;
    padding:5px 0;
    border-top:1px solid #aaa;
    border-bottom:1px solid #aaa;
}
.entr_item p {
    text-align:center;
    font-size:8px;
    margin:0;
}
.entr-desc {
    top:0;
    left:0;
}
.entr-link {
    cursor: pointer;
    display: inline-block;
    color: #bc8f8f;
    margin: 0 5px;
}
.entr-sort-item {
    clear: both;
    margin: 0 10px;
    padding: 10px;
    border: 1px solid #aaa;
    background: #ccc;
    overflow: hidden;
}
.org-duration {
    color: #aaa;
    margin-right: 5px;
}
.item-hint {
    /*
    position:absolute;
    z-index:1;
    width: 205px;
    background: rgba(0,0,0,0.8);
    border-radius: 4px;
    margin-left:10px;
    margin-top:5px;
    right:33px;
    top:10px;
    */
    padding:5px 10px;
}
.item-hint h1 {
    text-align:center;
    font-weight:bold;
    font-size:10px;
    color:#ae3;
}
.item-hint p {
    color:#eee;
}
.item-hint strong {
    font-weight:bold;
    color:limegreen;
}
.hcenter {
    margin:5px 0;
    text-align:center;
}
.item-hint li {
    color: white;
    overflow: hidden;
    clear: both;
}
.item-hint li.no {
    text-align: center;
}
.menu__sliders {
    float: left;
    margin-right: 100px;
}
.store__panel {
    float: left;
    margin-left: -5px;
    margin-top: -1px;
}
.store__panel .img16 {
    margin: 0 2px;
}
.store__item {
    float: left;
    margin: 0 3px;
    cursor: pointer;
    position: relative;
}
.store-cont {
    height: 22px;
    margin: 1px 5px 1px;
    border:1px solid #333;
    float:left;
    width:106px;
}
.store-bar-img {
    float: left;
    line-height: 24px;
}
.store-bar {
    padding: 3px;
    text-align:center;
    background:#FEFFC3;
    color:#777;
}
/* --------------------------------------- */
.taxi-sel {
    background-color:#fcff3d;
    cursor:pointer;
}
.counter {
    cursor:pointer;
	position:absolute;
	bottom:5px;
	right:5px;
    width: 42px;
    height: 42px;
    border-radius: 4px;
    border: 1px solid #777;
}
.counter img {
    padding: 5px;
}
.employee {
	position:absolute;
	top:5px;
	left:2px;
}
.empl-typed-list,
.scroll-x {
    overflow-x: auto;
}
.pos_bonus {
    padding: 10px;
    border: 1px solid #93946e;
    background: #feffc3;
    width: 190px;
    font-size: 14px;
}
.empl-item.selected {
    background:#5d5;
}
.empl-item.pos {
    opacity: 0.5;
}
.empl-item.buy {
    background: #f2f3b7;
    border: 1px solid #dcde78;
    opacity: 0.5;
}
#empl-selected p {
    padding:0px;
}
.empl-prop-cont {
    float: left;
    text-align: center;
    font-size: 14px;
}
.empl-prop {
    vertical-align:middle;
}
.empl-prop img, .empl-prop span {
    vertical-align:middle;
    margin-right: 2px;
}
.empl-selected-cont {
    overflow:hidden;
    width:32px;
    margin:0 auto;
}
.slider__onoff {
    float: left;
    vertical-align: middle;
    line-height: 20px;
}
.slider__lim {
    width: 120px;
    float: left;
    margin: 2px 10px 0 5px;
}
.budget_managment {
	float:left;
	width:230px;
	height:110px;
	padding-top:10px;
	margin-left:-100%;
}
.game_center {
	width:100%;
	height:110px;
	float:left;
}
.game_info {
	margin: 0 210px 0 310px;
	text-align:center;
}
.right_shadow {
	position:absolute;
	right:0;
	bottom:0;
	width:1px;
	height:1px;
	-moz-box-shadow:-3px -13px 9px 3px;
	-webkit-box-shadow:-3px -13px 9px 3px;
	box-shadow:-3px -13px 9px 3px;
}
.left_shadow {
	position:absolute;
	left:0;
	width:1px;
	height:1px;
	bottom:0;
	-moz-box-shadow:3px -13px 9px 3px;
	-webkit-box-shadow:3px -13px 9px 3px;
	box-shadow:3px -13px 9px 3px;
}
.actions {
    position:absolute;
    bottom:44px;
    right:0px;
    padding:3px;
}
.hover_opacity {
    background: #eee;
    border: 1px solid #777;
    position: absolute;
    max-height: 320px;
    opacity: 0.7;
    z-index: 910;
}
.player_actions {
    padding:10px;
    bottom:26px;
    right:26px;
}
.act_missed, .act_next {
	display:none !important;
}
.rate_img {
    vertical-align:middle;
    margin-top:-7px;
}
.rate {
    white-space: nowrap;
    color: yellow;
    vertical-align: middle;
    font-size: 0.9em;
}
.entr__header {
    overflow: hidden;
    padding: 10px;
    border-radius: 4px;
    background: #ddd;
}
.entr__title {
    font-size: 0.9em;
    color: #777;
    line-height: 15px;
}
.entr__part_header {
    margin: 10px 0;
    background: #c3f3b3;
    padding: 10px;
    border: 1px solid #bac7b2;
    color: #444;
    font-weight: bold;
}
.entr__unibar {
    background: #f3dcf5;
    border: 1px solid #d7c9da;
    padding: 10px;
    margin: 5px 0;
}
.entr__info {
    padding: 0 10px;
    font-size: 12px;
    text-align: right;
    color: #777;
}
.entr__info_green {
    font-weight: bold;
    color: green;
}
.entr__btext {
    color: green;
    font-weight: bold;
    font-size: 12px;
}
.entr-d {
    text-align: right;
    padding: 0 5px;
    color: #81a775;
    font-weight: bold;
    font-size: 14px;
}
.toggle-label {
}
.toggle-label a.expand:before {
    content: "\2227";
    padding-right: 5px;
}
.toggle-label a:before {
    content: "\2228";
    padding-right: 5px;
}
.entr_summary,
.factory_summary {
    overflow-y:auto;
    height:129px;
    padding:5px;
    float:right;
    margin-right:5px;
    background:#feffc3;
    border: 1px solid #757568;
}
.entr_summary {
    width:279px;
}
.factory_summary {
    width:219px;
}
.empl__cont {
    float: left;
    margin: 10px;
    cursor: pointer;
    border: 1px solid transparent;
    height: 120px;
}
.empl__cont.selected {
    background: #cbf1c4;
    border: 1px solid #aed0a0;
}
.empl__remove {
    position: absolute;
}
#player_attrs {
    display:none;
}
.results-log {
    height: 300px;
    max-height: 700px;
    text-align:center;
    overflow-y:auto;
    padding:5px;
}

#log {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 93%;
    max-width: 680px;
    z-index: 910;
    height: 85%;
}
#log-cl {
    left: 50%;
    top: 250px;
    width: 680px;
    transform: translateX(-50%);
    position: absolute;
}
#logmsg,
#logmsg-cl {
    text-align: center;
    overflow-y: auto;
    padding: 5px;
}
#logmsg {
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 4px;
    /*height: 130px*/;
    height: 100%;
}
#logmsg-cl {
    height: 400px;
}
#logmsg p,
#logmsg-cl p {
    margin:5px 0;
}

#player_log {
    background:#eee;
    border:1px solid #777;
    z-index:100;
    position:absolute;
    text-align:center;
    padding:10px;
    /*
    top:40px;
    left:3px;
    */
    top:27px;
    left:50%;
    margin-left:-250px;
    overflow-y:auto;
    height:300px;
    width:500px;
}
#player_log p {
    margin:5px 0;
}
#player_log_title {
    background:#eee;
    border:1px solid #777;
    position:absolute;
    z-index:100;
    position:absolute;
    text-align:center;
    padding:10px;
    top:27px;
    left:50%;
    margin-left:-50px;
    width:100px;
    opacity:0.9;
}
#player_log_content {
    position:absolute;
}
.store__item_used {
    overflow: hidden;
    padding: 5px 0 0 5px;
    font-size: 14px;
}
.players_table {
	border-collapse:collapse;
	border-spacing:0;
}
.players_table th {
	font-weight:normal;
	font-size:.7em;
}
.players_table td {
	border:1px solid #DACDD3;
}
.top_info_panel {
    cursor:pointer;
}
.budget_label {
	float:left;
    position:relative;
    cursor:pointer;
    margin: 0 3px;
}
.budget_cont {
	display:none;
	background:black;
	height:23px;
	left:165px;
	position:absolute;
	top:20px;
	width:157px;
	z-index:1000;
}
.slider {
	cursor: pointer;
	width: 80px;
	position:absolute;
	top:15px;
	z-index:1000;
}
.slider_slider {
	position:absolute;
    z-index: 1001;
}
.slider_hint {
	padding:10px;
	position:absolute;
	background:#222;
	width:270px;
    top: 9px;
    z-index: 1000;
}
.slider_hint.workplace {
    left: -40px;
}
.slider_hint.credit {
    left: -200px;
}
.slider_hint.charity {
    left: -120px;
}
.slider-minus {
    padding:0 3px;
}
.range {
    text-align: center;
    width:55px;
    display:inline-block;
    background: #222;
}
.handle {
    border-radius: 14px 14px 14px 14px;
    box-shadow: 0 0 6px #000000;
    background:#FFFFFF;
    border: 1px solid #000000;
    cursor: move;
    display: block;
    height: 10px;
    margin-top: 1px;
    position: absolute;
    top: -3px;
    width: 15px;
}
.factory_info {
	background:rgba(200,200,200,0.8);
	text-align:right;
	height:78%;
}
.player_buttons {
	text-align:center;
	float:left;
	width:100px;
}
.button_prior {
    cursor:pointer;
	margin-bottom: 10px;
	width:146px;
	display:inline-block;
	position: relative;
	padding: 0.8em 1em;
	background:#93E654;
	color:#414141 !important;
	border: 1px solid #aaa;
    font-size: 12px;
	font-weight:bold;
	text-align:center;
	border-radius:5px;
	user-select: none;	
	box-shadow:
		rgba(255, 254, 255, 0.597656) 0px 0.3em 0.3em inset,
		rgba(0, 0, 0, 0.148438) 0px -0.1em 0.3em inset, 
		#A99065 0px 0.1em 3px, 
		darkGreen 0px 0.1em 1px, 
		rgba(0, 0, 0, 0.199219) 0px 0.5em 5px;
}
a.button_prior {
	width:120px;
	padding: 0.5em 1em;
    text-transform: none;
	text-decoration: none;
	height:20px;
	line-height:21px;
}
.button_prior.dis,
.button_prior[disabled] {
    background: #ccc;
}
.button {
		margin: 7px 0px;
		display:inline-block;
		position: relative;
		
		padding: 0.5em 1em;
		background: orange;
		color: white;
		text-decoration: none;
		text-align:center;
		border: 1px solid darkOrange;
		
		   border-radius: 5px;
		   user-select: none;
			box-shadow: 
			rgba(255, 254, 255, 0.597656) 0px 0.3em 0.3em inset,
			rgba(0, 0, 0, 0.148438) 0px -0.1em 0.3em inset, 
			#AD781C 0px 0.1em 3px, 
			darkOrange 0px 0.3em 1px, 
			rgba(0, 0, 0, 0.199219) 0px 0.5em 5px;
	}

	.button:active, .button_prior:active {
		
		box-shadow: 
			rgba(255, 254, 255, 0.597656) 0px 0.3em 0.3em inset,
			rgba(0, 0, 0, 0.148438) 0px -0.1em 0.3em inset, 
			#AD781C 0px 0.1em 3px;
			
		-o-transform: translateY(0.3em);
	}
.button_on_main {
    cursor:pointer;
    background:#ccc;
    border:1px solid #777;
    color:aliceBlue;
    box-shadow:rgba(255, 254, 255, 0.597656) 0px 0.3em 0.3em inset, rgba(0, 0, 0, 0.148438) 0px -0.1em 0.3em inset, #AD781C 0px 0.1em 3px, #777 0px 0.3em 1px, rgba(0, 0, 0, 0.199219) 0px 0.5em 5px;
}
.button_prior_on_main {
    cursor:pointer;
    background:steelBlue;
    border:1px solid #777;
    color:peachPuff;
    box-shadow:rgba(255, 254, 255, 0.597656) 0px 0.3em 0.3em inset, rgba(0, 0, 0, 0.148438) 0px -0.1em 0.3em inset, #AD781C 0px 0.1em 3px, #777 0px 0.3em 1px, rgba(0, 0, 0, 0.199219) 0px 0.5em 5px;
}
.cell_button {
    display:block;
}
.overlay_cont {
	padding:20px;
	text-align:center;
	width:400px;
	height:160px;
	position:relative;
}
.overlay_cont li {
	display:inline-block;
}
.on-top {
    z-index: 10000 !important;
}
div.overlay {
	box-shadow:0 0 90px 5px black;
	background-color:white;
	border:2px solid black;
	top:30px !important;
	left:50% !important;
	margin-left:-350px;
	display:none;
	z-index:10000;
}
.overlay_player {
	box-shadow:0 0 90px 5px black;
	background-color:white;
	border:2px solid black;
	top:50% !important;
	left:50% !important;
	margin-left:-200px;
	margin-top:-80px;
	display:none;
	z-index:10000;
}
.cont-empls-lvl {
    clear:both;
}
.cont-empls-lvl h3 {
    font-weight:bold;
    padding:5px;
    background-color:#eee;
}
#balput {
    min-height: 120px;
}
#balexchg {
    min-height: 160px;
}
/* =========================== DIALOGS ============================== */
._tip {
    font-size: 12px;
    font-style: italic;
}
.dialog-winner {
    padding: 10px; 
}
.d-white,
.d-transparent {
    padding: 10px;
}
.d-transparent {
    background: rgba(0, 0, 0, 0.5);
    color: white;
}
.d-white {
    background: #fff;
}
.d-white h1 {
    text-align: center;
    font-weight: bold;
}
.d-bank {
    position: relative;
    background: url(../images/bank.jpg) 50% 0 no-repeat;
    height: 400px;
    width: 320px;
}
.d-bank_over {
    display: table;
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
}
.d-bank_cont {
    display: table-cell;
    vertical-align: middle;
}
.d-bank_inner {
    padding-bottom: 5px;
    background: rgba(255,255,255,0.7);
}
.d-bank_inner.open {
    position: absolute;
    bottom: 29px;
    padding: 10px;
    left: 5px;
}
/* ------------------------ UI ------------------------- */
.ui-dialog.noclosebutton {
    overflow:initial;
}
.ui-dialog.noclosebutton .ui-dialog-titlebar-close {
    display:none;
}
.ui-sortable li {
    overflow: hidden;
    padding: 10px;
    border: 1px solid #ccc;
    margin: 0 auto 1px;
    background-color: #eee;
    width: 70%;
}
/* -------------- AGENCY ----------------------- */
.agency-spec {
    float:left;
    padding: 5px;
    width: 110px;
    text-align: center;
    border: 1px solid transparent;
}
.agency-spec p {
    text-align:center;
}
.agency-spec.selected {
    background: #cbf1c4;
    border: 1px solid #aed0a0;
}
.agency-calc {
    margin:5px;
}
.agency-calc p {
    margin:5px 0;
}
/* -------------- OFFICE ----------------------- */
.office-item {
    padding: 10px;
    border-top: 1px solid #ccc;
    font-size: 14px;
    overflow: hidden;
}
.office-item h1 {
    margin-left: 10px;
    color: #ad781c;
    float: left;
    line-height: 32px;
}
.office-empl {
    float:left;
    min-height:1px;
    margin: 4px;
}
.office-empl-attr {
    padding:0 5px;
    color:gray;
    background:#eee;
    font-size:10px;
}
.office-empl-green {
    margin:5px 0 10px;
    color:#2b2;
    font-size:10px;
    text-align:right;
    padding:0 5px;
    line-height:10px;
}
.office-bonus-text {
    font-weight:bold;
    color:green;
    margin-right:10px;
}
/* ----------- COMPONENTS ---------------------- */
/* ------------- EVENTS ------------------------ */
.event__dialog {
    background: #fff;
    border-radius: 4px;
    text-align: center;
}
.event-title {
    font-weight: bold;
}
.event-body {
    position: relative;
    padding: 10px;
}
.event-th-title {
    vertical-align: middle;
    line-height: 32px;
    margin-left: 42px;
    cursor: pointer;
    color: #a36e35;
}
.event-th-img {
    float: left;
    background: url(../images/events/events32.png);
}
.event-th1 {
    background-position: 0px 0px;
}
.event-th2 {
    background-position: -32px 0px;
}
.event-th3 {
    background-position: -64px 0px;
}
.event-th4 {
    background-position: -96px 0px;
}
.event-th5 {
    background-position: -128px 0px;
}
.event-th6 {
    background-position: 0px -32px;
}
.event-th7 {
    background-position: -32px -32px;
}
.event-th8 {
    background-position: -64px -32px;
}
.event-th9 {
    background-position: -96px -32px;
}
.event-th10 {
    background-position: -128px -32px;
}
.event-th11 {
    background-position: 0px -64px;
}
.event-th12 {
    background-position: -32px -64px;
}
.event-th13 {
    background-position: -64px -64px;
}
.event-th14 {
    background-position: -96px -64px;
}
.event-th15 {
    background-position: -128px -64px;
}
.event-th16 {
    background-position: 0px -96px;
}
.event-th17 {
    background-position: -32px -96px;
}
.event-th18 {
    background-position: -64px -96px;
}
.event-th19 {
    background-position: -96px -96px;
}
.event-th20 {
    background-position: -128px -96px;
}
.event-th21 {
    background-position: 0px -128px;
}
.event-th22 {
    background-position: -32px -128px;
}
.event-th23 {
    background-position: -64px -128px;
}
.event-th24 {
    background-position: -96px -128px;
}
.event-th25 {
    background-position: -128px -128px;
}
.event-img {
    width: 256px;
    height: 256px;
    margin: 10px auto;
    background: url(../images/events/events256.png);
}
.event1 {
    background-position: 0px 0px;
}
.event2 {
    background-position: -256px 0px;
}
.event3 {
    background-position: -512px 0px;
}
.event4 {
    background-position: -768px 0px;
}
.event5 {
    background-position: -1024px 0px;
}
.event6 {
    background-position: 0px -256px;
}
.event7 {
    background-position: -256px -256px;
}
.event8 {
    background-position: -512px -256px;
}
.event9 {
    background-position: -768px -256px;
}
.event10 {
    background-position: -1024px -256px;
}
.event11 {
    background-position: 0px -512px;
}
.event12 {
    background-position: -256px -512px;
}
.event13 {
    background-position: -512px -512px;
}
.event14 {
    background-position: -768px -512px;
}
.event15 {
    background-position: -1024px -512px;
}
.event16 {
    background-position: 0px -768px;
}
.event17 {
    background-position: -256px -768px;
}
.event18 {
    background-position: -512px -768px;
}
.event19 {
    background-position: -768px -768px;
}
.event20 {
    background-position: -1024px -768px;
}
.event21 {
    background-position: 0px -1024px;
}
.event22 {
    background-position: -256px -1024px;
}
.event23 {
    background-position: -512px -1024px;
}
.event24 {
    background-position: -768px -1024px;
}
.event25 {
    background-position: -1024px -1024px;
}
.event-scenario {
    padding: 0 10px;
    text-align: center;
    color: #ad781c;
}
.event-duration {
    position: absolute;
    left: 10px;
    background: url(../images/events/sandglass32.png) no-repeat;
    padding-left: 32px;
    line-height: 32px;
    vertical-align: middle;
    color: #ab7900;
    height: 32px;
}
.event-duration-end {
    font-size: 10px;
    line-height: 10px;
    text-align: left;
}
.event-points {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 64px;
    height: 64px;
    background: url(../images/events/points64.png);
    font-size: 32px;
    color: #5ca713;
    font-weight: bold;
    text-align: center;
    line-height: 64px;
}
.event-reward {
    background: url(../images/events/reward64.png) no-repeat;
    padding-left: 74px;
    min-height: 64px;
    text-align: left;
}
.event-reward-effect {
    font-size: 12px;
    text-align: left;
    font-weight: bold;
    color: #a36e35;
}
.event-reward-effect li {
    line-height: 16px;
    vertical-align: middle;
}
.event-data, .event-winners, .event-form {
    margin: 10px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #bfbf79;
    background: #dbdca8;
}
.event-winners {
    border: 1px solid #96bb66;
    background: #acd28a;
}
.event-form {
    border: 1px solid #aaa;
    background: #ccc;
    text-align: left;
}
.event-form-action {
    text-align: right;
}
/* ------------- MARKET ------------------------ */
.bg {
    width: 700px;
    height: 570px;
}
.market-bg {
    position: relative;
    background: url(../images/market/background.png) no-repeat;
}
.market__desk {
    position: absolute;
    background: url(../images/market/desk.png) no-repeat;
    padding-top: 30px;
    padding-left: 26px;
    width: 170px;
    height: 114px;
    top: 113px;
    left: 22px;
}
.market__desk_item {
    height: 30px;
    width: 120px;
    margin: 5px 0;
    cursor: pointer;
    text-align: right;
}
.market__desk_sale {
    background: url(../images/market/desk_sale.png) no-repeat;
}
.market__desk_sale.selected, .market__desk_sale:hover {
    background: url(../images/market/desk_sale_on.png) no-repeat;
}
.market__desk_buy {
    background: url(../images/market/desk_buy.png) no-repeat;
}
.market__desk_buy.selected, .market__desk_buy:hover {
    background: url(../images/market/desk_buy_on.png) no-repeat;
}
.market__desk_saleall,
.market__trader_buy,
.market__trader_sale {
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.market__desk_saleall {
    width: 120px;
    height: 30px;
    background: url(../images/market/saleall.png) no-repeat;
}
.market__desk_saleall:hover {
    background: url(../images/market/saleall_on.png) no-repeat;
}
.market__saleall {
    position: absolute;
    width: 160px;
    height: 30px;
    bottom: 33px;
    right: 270px;
}
.market__trader {
    position: absolute;
    width: 214px;
    height: 90px;
    bottom: 30px;
    right: 30px;
    z-index: 1;
}
.market__desk_item a {
    display: block;
    width: 100%;
    height: 100%;
}
.market__trader_buy {
    background: url(../images/market/buy.png);
}
.market__trader_buy:hover {
    background: url(../images/market/buy_on.png);
}
.market__trader_buy_dis {
    background: url(../images/market/buy_dis.png);
}
.market__trader_sale {
    background: url(../images/market/sale.png);
}
.market__trader_sale:hover {
    background: url(../images/market/sale_on.png);
}
.market__trader_sale_dis {
    background: url(../images/market/sale_dis.png);
}
.market__trader_total {
    position: absolute;
    display: block;
    font-weight: bold;
    color: #fed350;
    line-height: 16px;
    text-shadow: 0px 0px 5px black;
    right: 75px;
    bottom: -5px;
}
.market__trader_total img {
    vertical-align: middle;
}
.market__cont {
    position: absolute;
    left: 50px;
    top: 50%;
    margin-top: -60px;
    height: 250px;
    overflow-y: auto;
}
.market__text {
    position: absolute;
    top: 130px;
    left:210px;
    padding: 10px;
    font-weight: bold;
    text-align: center;
    color: #f5debe;
    width: 430px;
    height: 45px;
    font-size: 12px;
    overflow-y: auto;
    background: rgba(0,0,0,0.4);
}
.market__item_total {
    background: #fed350;
    border: 1px solid #b59534;
    text-align: center;
    font-weight: bold;
    color: #a36e35;
}
.market-item {
    padding:3px;
}
.market-item img, .market-item span {
    vertical-align: middle;
}
.market_input {
    text-align: center;
}
.market_input input {
    text-align: right;
    background: #dc6f3c;
    font-weight: bold;
    border: 1px solid #a36e35;
    color: #562617;
}
.res64 {
    position: relative;
    float: left;
    width: 82px;
    font-size: 12px;
    margin: 5px 10px 15px;
}
.res64 .hint {
    text-align: center;
    color: #fff;
    font-size: 10px;
}
.res64.offd {
    background: #c8f1c8;
}
.res64.selected {
    background: #cfe7f7;
}
.res64_rate {
    position: absolute;
    top: 0px;
    background: rgba(0,0,0,0.3);
    text-align: center;
    width: 100%;
    z-index: 10;
}
.res_buy_total, .res_sale_total {
    vertical-align:middle !important;
}
.product-price-cont {
    height:22px;
    border:1px solid gray;
    width:106px;
    display:inline-block;
}
.product-price {
    color:#ccc;
    display:inline-block;
    padding:3px;
    float:left;
    text-align:center;
    background:#FEFFC3;
}

.abutton {
    padding:5px;
    display:inline-block;
    text-decoration:none;
    border:1px solid #aaa;
    background-color:#eee;
}
.place_enterprise {
}
.place_enterprise h2 {
    font-weight:bold;
    margin:10px 0;
}
.place-info {
    background:#ddd;
    border:1px solid gray;
    padding:10px;
    margin: 5px;
    text-align: center;
}
.place-info p {
    font-weight:bold;
    margin: 10px 0;
}
.place-info ul {
    list-style:circle inside;
    margin-left:15px;
}
.place-info ul li {
    margin:5px 0;
}
.place__title {
    font-weight: bold;
    color: #3345e0;
}
.place__entr_choose {
    cursor: pointer;
}
.place__entr_choose.selected {
    background: #fed350;
}
.place__entr_img {
    float: left;
    margin: 10px;
}
.place_info, .entr-data {
    background: #ddd;
    padding: 5px 10px;
    margin: 5px 5px 5px 85px;
    color: #777;
}
.place_info h3, .place-info h3 {
    color: #a36e35;
    font-weight: bold;
    margin-bottom: 10px;
}
.entr-data {
    margin: 0px 0px 0px 75px;
}
.use-cont {
    font-size: 12px;
    color: #000;
}
.use-lb {
    color: #999;
}
.place_menu, .place_button {
    text-decoration:none;
    display:inline-block;
    width: 94%;
    padding:10px;
}
.place_action {
    padding: 10px 0;
    color: #467b0d;
    font-weight: bold;
    cursor: pointer;
}
.place_item_sel {
    background:#ccc;
}
.redline, .factory-size-radio {
    padding-left:20px;
}
.factory-size-radio label {
    cursor:pointer;
}
.factory_item_sel {
    border:1px solid #ccc;
}
/* ------------------ EMPLOYEE CARD ------------------------ */
.empl-cont {
    width: 190px;
    background: #eee;
}
.emp-img-cont {
    float: left;
    margin: 5px 10px;
}
.emp-exp-cont {
    font-size: 12px;
    color: #777;
    text-align: center;
    margin-top: 5px;
}
/* ------------------------ ADVENTURE -------------------------- */
.adv {
    margin: 10px;
}
.adv.selected {
    background: #fed350;
}
/* ------------------------CELL-INFO --------------------------- */
.info-actions li {
    line-height:24px;
    margin:10px 0;
}
.info-actions li img {
    vertical-align:middle;
}
/* ------------- INFO PAGE ----------------- */
.infopart {
    display:none;
    margin-left:10px;
    float:left;
    width:485px;
}
.infopart h1, .main_part h1 {
    padding: 10px;
    font-weight: bold;
    border: 1px solid gray;
    margin-bottom: 5px;
    background: #bbb;
}
.infopart h2, .main_part h2 {
    clear:both;
    padding: 4px;
    background: #ccc;
    border: 1px solid gray;
    font-weight: bold;
    color: #a77;
    cursor: pointer;
}
.main_part h3 {
    margin:5px;
    padding:5px;
    border:1px solid #ccc;
    color:green;
    font-weight:bold;
    background:#fff;
}
.info-title {
    text-decoration:none;
    color:gray;
    cursor: pointer;
}
.info-title.active {
    background:#ccc;
    font-weight:bold;
}
.infocontents p {
    padding:2px 0;
}
.img_left {
    float:left;
    margin:0px auto;
}
.cell_img {
    float:left;
}
.cell_inst {
    position:relative;
    float:left;
    border:1px solid #aaa;
    background-color:#feffc3 !important;
    margin:0px auto;
}
.cell_desc {
    margin-bottom:10px;
    padding:0 10px;
}
.cell_desc p {
    margin:5px 0;
}
.cell_desc img {
    vertical-align:middle;
}
/* ------------------- USER NOTIFICATIONS ---------------------- */
.unotif_txt_cont {
    text-align: center;
    width: 300px;
    margin: 0 auto 20px;
}
.unotif_txt_cont h1 {
    margin-bottom: 20px;
}
.unotif_txt_cont p {
    margin: 5px 0;
}
.unotif_txt_cont .button_prior {
    margin-top: 30px;
}
/* ----------------------- CHAT -------------------------------- */
#lobby {
    min-height: 599px;
}
#chat_message {
    width: 130px;
}
#chat_messages {
	padding: 3px;
	height:111px;
	overflow: auto;
}
#chat_messages p {
	margin:0;
}
#chat {
    position: absolute;
    left: 55px;
    bottom: 5px;
    z-index: 911;
    background: rgb(185, 230, 102, 0.8);
    border: 1px solid #89ab2d;
    border-radius: 4px;
    width: 200px;
}
#lobby-chat {
    color: #855e35;
}
#lobby-chat p {
    margin:5px;
}
#lobby-chat #chat_messages {
    height: 200px;
}
.chat_message_cont {
    padding: 2px;
    color: #556d75;
    text-align: center;
}
.errorlist {
}
.errorlist li {
    font-weight:bold;
    color:#b22;
    text-align:right;
}
.chat_time {
	color:green;
	font-size: 0.7em;
}
.chat_mess {
}
.w300, .w400, .w500 {
    margin: 0 auto;
}
.w300 {
    width: 300px;
}
.w400 {
    width: 400px;
}
.w500 {
    width: 500px;
}
.gameinfo {
    overflow: hidden;
    font-size: 15px;
}
.gameinfo dt {
    clear: both;
    float: left;
    margin: 5px 0;
}
.gameinfo dd {
    margin: 5px 0;
    text-align: right;
}
.fl32 {
    float: left;
    line-height: 32px;
}
.floatleft {
    float: left;
}
.floatright {
    float: right;
}
.inline {
    float: none;
    display: inline-block;
    vertical-align: middle;
}
.inlineleft {
    display:inline-block;
    float:left;
}
.inlineright {
    display:inline-block;
    float:right;
}
.inlinelink {
    display:inline-block;
    vertical-align: top;
    cursor: pointer;
}
.relative {
    position:relative;
}
.alone {
    float:none;
}
.left-title {
    float:left;
    overflow:hidden;
}
.left-title input {
    vertical-align: middle;
}
.left-title a {
    display: inline-block;
    line-height: 20px;
}
.venture-warn {
    overflow: hidden;
    padding: 0 10px !important;
    font-size: 10px;
    color: #ca5c5c;
}
.venture-title {
    text-decoration: none;
    color: black;
    margin-right: 2px;
}
.venture-lim {
    color:#CE8B17 !important;
}
.right-props {
    float: right;
}
.screen420 {
    display:none;
}
.pl-entrs {
    float: left;
}
#actions-panel {
    position: absolute;
    bottom: 0px;
    right: 0px;
    background: rgba(0,0,0,0.8);
    border-radius: 25px;
    margin: 2px;
    padding: 5px;
    z-index : 10;
}
#player-panel {
    position: absolute;
    background: rgba(0,0,0,0.8);
    bottom: 5px;
    left: 5px;
    padding: 5px;
    border-radius: 5px;
    z-index : 10;
}
#player-panel div {
    cursor: pointer;
}
#player-panel .cnt {
    position: absolute;
    line-height: 16px;
    vertical-align: middle;
    text-align: center;
    font-weight: bold;
    border-radius: 8px;
    height: 16px;
    width: 16px;
    right: 2px;
    bottom: 3px;
    cursor: pointer;
    color: #a36e35;
    background: #fed350;
    font-size: 10px;
    box-shadow: 0px 1px 3px black;
}
.pl-actions {
    padding: 3px;
    float: left;
    height: 60px;
}
.pl-turn {
    float: left;
}
/***************** SLIDE **************************/
.slide-cont {
    position: relative;
}
.slide-left, .slide-right {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 50%;
    margin-top:-8px;
}
.slide-left {
    left: 5px;
    background: url(../images/aleft.png);
}
.slide-right {
    right: 5px;
    background: url(../images/aright.png);
}
.slide-label {
    position: absolute;
    height: 16px;
    line-height: 16px;
    font-size: 10px;
    vertical-align: middle;
    color: #777;
    text-align: left;
    top: 4px;
    left: 10px;
}
.hidegt730 {
    display: none;
}
/************* BALANCE COINS **********************/
.coin-val {
    vertical-align: middle;
    font-weight: bold;
    font-size: 14px;
    margin: 0 3px;
}
.coin-cnt {
    vertical-align: middle;
    margin-left: 5px;
    font-size: 16px;
}
/***************** SCREEN *************************/
@media screen and (max-width:1250px) {
    .player_menu {
        margin-bottom:5px;
    }
    #levelup_lb_cont {
        margin-bottom:5px;
    }
    .menu-cont-right {
        padding-bottom:4px;
    }
    .bottom_panels.player, .bottom_panels.guest {
        margin-left:-354px;
    }
    .pl-entrs {
        float: none;
        display:none;
    }
}
@media screen and (max-width:990px) {
    .game_menu {
        height: 70px;
    }
}
@media screen and (max-width:750px) {
    .screen750.info_bonus_vertical {
        margin: 0;
    }
    .screen750.img_left,
    .screen750.cell_inst {
        float: none;
        margin: 10px auto;
    }
    .screen750.body_card_link {
        width: 420px;
    }
    .hidegt730 {
        display: block;
    }
    .hidelt730 {
        display: none;
    }
    .cachedialog {
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }
    .main-menu {
        margin: 0;
    }
    .main-menu li {
        margin-left: 14px;
        font-size: 12px;
        line-height: 38px;
    }
    .store__panel {
        clear: left;
        margin-top: 2px;
    }
    .user_label {
        font-size: 14px;
        margin-right: 10px;
    }
    .head {
        background: url(../images/land_730.jpg) 50% 0 no-repeat;
        height: 500px;
    }
    .headnight {
        background: none;
    }
    .head-content {
        width: auto;
    }
    .head-title {
        color: #a36e35;
    }
    #head-logo-ru,
    #head-logo-en {
        height: 101px;
        width: 300px;
        margin: 0 auto;
    }
    #head-logo-ru {
        background-image: url(../images/logo300_ru.png);
    }
    #head-logo-en {
        background-image: url(../images/logo300_en.png);
    }
    .head__content {
        margin: 20px 0;
        background: rgba(210,210,210,0.5);
    }
    .user-info {
        margin-right: 8px;
    }
    .body_mainmenu__part {
        float: none;
    }
    .body_maintext, .info_maintext {
        padding: 0 20px;
        font-size:18px;
        line-height:26px;
    }
    #images {
        height: 397px;
    }
    ul#images li {
        height: 357px;
    }
    .kwicks-inner-div {
        padding: 5px;
    }
    .images-li-last {
        border-right: 5px solid #a08a43;
    }
    .kwicks-screen {
        width: 532px;
        height: 347px;
    }
    .kwicks-screen1 {
        background: url(../images/screens/screen1_532.jpg);
    }
    .kwicks-screen2 {
        background: url(../images/screens/screen2_532.jpg);
    }
    .kwicks-screen3 {
        background: url(../images/screens/screen3_532.jpg);
    }
    .kwicks-screen4 {
        background: url(../images/screens/screen4_532.jpg);
    }
    .kwicks-screen5 {
        background: url(../images/screens/screen5_532.jpg);
    }
    .kwicks-screen6 {
        background: url(../images/screens/screen6_532.jpg);
    }
    /* GAME BOARD */ 
    .bottom_panels.player, .bottom_panels.guest {
        margin-left:0px;
        left:0px;
        width:100%;
    }
    .margin_width {
        margin: 0;
    }
}
@media screen and (max-width:680px) {
    .body_mainmenu_container {
        max-width: 295px;
    }
    .body_mainmenu_container li {
        width: 109px;
    }
    .main_chat, .current_games {
        width: auto;
        float: none;
    }
    .enter {
        background: none;
        width: auto;
        height: 200px;
    }
    .enter_button {
        right: 50%;
        top: 50%;
        margin-right: -30px;
        margin-top: 75px;
    }
    .register {
        top: 0px;
    }
    .remind_password {
        top: 30px;
    }
}
@media screen and (max-width:650px) {
    #fortune_lb {
    }
    #levelup_lb_cont {
        position:absolute;
        bottom:45px;
        right:3px;
    }
    .menu-cont-right730, .menu-cont-item730 {
        position: absolute;
        bottom: 10px;
        right: 20px;
    }
    .menu_dialog  {
        top:115px;
        left:10px;
        margin-left:0px;
    }
    .item-hint {
        right:17px;
    }
}
@media screen and (max-width:600px) {
    .body_card_container {
        width: 300px;
    }
    .body_card_link {
        width: 280px !important;
    }
}
@media screen and (max-width:510px) {
    .info_bonus_container {
        width: auto;
    }
    .info_bonus_container li {
        margin: 0 auto;
        float: none; 
    }
    .inrow, .info_vertical .info_bonus_cards {
        float: none;
    }
    .img_left,
    .cell_inst {
        float:none;
        margin:10px auto;
    }
    .info_bonus_vertical {
        margin: 0;
    }
    .info_bonus_body {
        margin: 0 30px;
    }
    .info_basetext {
        width: 95%;
        padding: 20px 0;
    }
    .info_quote {
        padding: 0 0 20px 0;
    }
    .body_text {
        padding: 0 20px; 
    }
    .game_join_container {
        width: auto;
    }
    .game_create_vertical, .game_join_vertical {
        margin: 0;
    }
    .game_join_vertical dl {
        padding: 0 20px;
    }
    .game_join_image {
        display: none;
    }
    .game__invite_cont {
        height: 535px;
    }
    .chars-img-exp {
        margin: 0 auto;
    }
    .chars-attrs-params {
        font-size: 14px;
    }
    .chars-attrs-cont {
        float: none;
    }
    .chars-attrs-cont dt {
        width: 105px;
    }
    .chars-games {
        width: 140px;
    }
    .w500 {
        width: auto;
    }
}
@media screen and (max-width:450px) {
    .info_cells_card_container {
        width: 300px;
    }
}
@media screen and (max-width:420px) {
    .head {
        background: url(../images/land_420.jpg) 50% 0 no-repeat;
        height: 500px;
    }
    .head__content {
        background: rgba(210,210,210,0.7);
    }
    #images {
        height: 233px;
    }
    ul#images li {
        height: 193px;
    }
    .kwicks-screen {
        width: 280px;
        height: 183px;
    }
    .kwicks-screen1 {
        background: url(../images/screens/screen1_280.jpg);
    }
    .kwicks-screen2 {
        background: url(../images/screens/screen2_280.jpg);
    }
    .kwicks-screen3 {
        background: url(../images/screens/screen3_280.jpg);
    }
    .kwicks-screen4 {
        background: url(../images/screens/screen4_280.jpg);
    }
    .kwicks-screen5 {
        background: url(../images/screens/screen5_280.jpg);
    }
    .kwicks-screen6 {
        background: url(../images/screens/screen6_280.jpg);
    }
    .floatright-sm {
        float: right;
    }
    #player_levelup {
        padding:5px;
        width:280px;
        margin-left:-150px;
    }
    .top_chars {
        font-size: 12px;
    }
    .menu_dialog  {
        width:280px;
    }
    .bottom_panels {
        position: initial;
        background: none;
    }
    .menu__sliders {
        margin-left: 55px;
        margin-right: 0px;
        clear: left;
    }
    .game_menu {
        height:86px;
    }
    #venture_empls {
        clear: left;
        margin-top: 5px;
    }
    #char-img {
        display: none;
    }
    .notifs-cont {
        bottom: 85px;
    }
    #levelup_lb_cont {
        position:absolute;
        bottom:40px;
        z-index:97;
        background:black;
        padding:5px;
        left:5px;
        right:initial;
    }
    #venture_empls {
        margin: 0px;
    }
    .notify-msg {
    }
    .map-button {
        right:5px;
    }
    .screen420 {
        display:block;
    }
}
@media screen and (max-height:370px) {
    .bottom_panels {
        position: initial;
        height: 100%;
        bottom: 0;
        right: 0;
    }
    .screen420 {
        display:block;
    }
}
