.badge {
    padding: 3px;
    border-radius: 5px;
    white-space: nowrap;
    border: 1px solid #777;

    &.points {
        background: #feffc3;
        border: 1px solid #babb8c;
    }
    &.pts {
        background: #fed350;
        border: 1px solid #c7a028;
        color: #714f15;
    }

    &.balance {
        display: inline-block;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        border-radius: 4px;
        padding: 2px 4px;
        background-color: rgba(0,0,0,0.2);
        border: none;
    }
}

