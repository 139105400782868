/*
.dialog__main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5001;
}
.dialog__back {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5000;
    background: #000;
    opacity: 0.7;
}
.dialog {
    position: absolute;
    width: 60%;
    left: 50%;
    margin-left: -30%;
    z-index: 5001;
    background: #fff;
    border: 1px solid #777;
    border-radius: 4px;
    padding: 10px;
}
.dialog {
    top: 20%;
    left: 50%;
    width: 80%;
    margin-left: -40%;
    position: absolute;
    margin-bottom: 52px;
}
*/
.modaldialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5000;

    & .main,
    & .base {
        position: absolute;
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        width: 100%;
        height: 100%;
        z-index: 5001;
    }
    & .main {
        background: rgba(0,0,0,0.5);;
        /*animation-name: d_background;*/
        /*animation-duration: 0.2s;*/
        /*transition: all 0.3s;*/
    }
    @-webkit-keyframes d_background {
        from {background: none;}
        to {background: rgba(0,0,0,0.5);}
    }
    @keyframes d_background {
        from {background: none;}
        to {background: rgba(0,0,0,0.5);}
    }
    @-webkit-keyframes appear {
     0% {
         opacity: 0;
         pointer-events: none;
         -webkit-transform: scale(0.8);
         transform: scale(0.8);
         /*
         -webkit-transform: translateY(-10px);
                 transform: translateY(-10px);
                 */
     }
    }
    @keyframes appear {
     0% {
         opacity: 0;
         pointer-events: none;
         -webkit-transform: scale(0.8);
         transform: scale(0.8);
         /*
         -webkit-transform: translateY(-10px);
                 transform: translateY(-10px);
                 */
     }
    }

    & .dialog {
        margin: 0 auto 52px;
        max-width: 770px;
        /*-webkit-animation: appear 350ms;*/
        /*        animation: appear 350ms;*/
    }
    & .close {
        cursor:pointer;
        padding:10px;
        font-weight:bold;
        text-align:center;
        float: none;
    }
    & .close {
        background: url(../static/images/dialog_close.png) no-repeat 100%;
        color: #9e9e9e;
        text-align: right;
        padding-right: 37px;
        line-height: 32px;
    }
    & .close {
        margin: 0 10px;
    }
    & .close:hover {
        background: url(../static/images/dialog_close_over.png) no-repeat 100%;
        color: #fff;
    }
}
