.tt-cont {
    position: absolute;
    z-index: 10000;
    box-shadow: 0px 0px 5px #777;
}
.ui-tooltip {
    position: absolute;
    z-index: 9999;
    max-width: 300px;
    padding: 10px;
    box-shadow: 0px 0px 5px #777;
    background: #fff;
    color: #333;
    border: 1px solid #c5c5c5;
    border-radius: 4px;
}
