.deal_item {
    cursor: pointer;
}
.deal-panel {
    width: 280px;

    & h2 {
        text-align: center;
        color: #aaa;
        margin: 5px;
    }

    & .chs_hdr {
        cursor: pointer;
        padding: 10px;
        text-align: center;
    }
}
