$bg-color: #f5f5dc;
$shadow: #555;

.tooltip {
  position: relative;
   
  &__item {
    position: absolute;
    min-width: 190px;
    padding: 10px;
    visibility: hidden;
    opacity: 0;
    transition: all .250s cubic-bezier(0, 0, 0.2, 1);
    color: #484848;
    border: 1px solid #b0b172;
    border-radius: 3px;
    font-weight: 500;
    box-shadow: 0 2px 1px $shadow;
    z-index: 5011;
    font-size: 12px;
    line-height: 16px;
    background: $bg-color;
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
    }
  }
  
  &__initiator {
    cursor: pointer;
    z-index: 5012;
  }
  
  &[data-direction="left"] {
    
    .tooltip__initiator:hover ~ .tooltip__item {
      transform: translate3d(0, -50%, 0);
      visibility: visible;
      opacity: 1;
    }
    
    .tooltip__item {
      top: 50%;
      right: calc(100% + 1em);   
      transform: translate3d(15px, -50%, 0);

      &:after {
        top: 50%;
        right: -0.5em;
        transform: translate3d(0, -50%, 0);
        border-width: 0.5em 0 0.5em 0.5em;
        border-color: transparent transparent transparent $bg-color;
        -webkit-filter: drop-shadow(1px 2px 0 $shadow);
        filter: drop-shadow(1px 2px 0 $shadow);
      }
    }
  }
  
  &[data-direction="bottom"] {
    
    .tooltip__initiator:hover ~ .tooltip__item {
      transform: translate3d(-50%, 0, 0);
      visibility: visible;
      opacity: 1;
    }
    
    .tooltip__item {
      top: calc(100% + 1em);
      left: 50%; 
      transform: translate3d(-50%, -15px, 0);

      &:after {
        top: -0.5em;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        border-width: 0 0.5em 0.5em 0.5em;
        border-color: transparent transparent $bg-color transparent;
        -webkit-filter: drop-shadow(1px -1px 0 $shadow);
        filter: drop-shadow(1px -1px 0 $shadow);
      }
    }
  }

  &[data-direction="right"] {
    
    .tooltip__initiator:hover ~ .tooltip__item {
      transform: translate3d(0, -50%, 0);
      visibility: visible;
      opacity: 1;
    }
    
    .tooltip__item {
      top: 50%;
      left: calc(100% + 1em);   
      transform: translate3d(-15px, -50%, 0);

      &:after {
        top: 50%;
        left: -0.5em;
        transform: translate3d(0, -50%, 0);
        border-width: 0.5em 0.5em 0.5em 0;
        border-color: transparent $bg-color transparent transparent;
        -webkit-filter: drop-shadow(0 2px 0 $shadow);
        filter: drop-shadow(0 2px 0 $shadow);
      }
    }
  }
  
  &[data-direction="top"] {
    
    .tooltip__initiator:hover ~ .tooltip__item {
      transform: translate3d(-50%, 0, 0);
      visibility: visible;
      opacity: 1;
    }
    
    .tooltip__item {
      bottom: calc(100% + 1em);
      left: 50%; 
      transform: translate3d(-50%, 15px, 0);

      &:after {
        bottom: -0.5em;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        border-width: 0.5em 0.5em 0 0.5em;
        border-color: $bg-color transparent transparent transparent;
        -webkit-filter: drop-shadow(2px 2px 0 $shadow);
        filter: drop-shadow(2px 2px 0 $shadow);
      }
    }
  }
}
