$card-width: 148px;

.court-bg {
    background: url(../static/images/court256.png) 50% 0 no-repeat;
    max-width: 700px;
    min-height: 260px;
    margin: 0 auto;
    text-shadow: 0 0px 4px #fff;
}

.lawyer {
    width: $card-width;
    height: 188px;
    position: relative;
    padding: 5px;
    border: 1px solid #ccc;
    margin: 0 auto;

    &-cont {
        width: 640px;
        margin: 0 auto;
    }

    & .quote {
        font-style: italic;
        color: #777;
        padding: 5px;
        font-size: 12px;
        text-align: center;
    }

    & .strength {
        position: absolute;
        bottom: 6px;
        width: 90%;

        & .num {
            font-size: 32px;
            color: green;
            font-weight: bold;
            text-align: right;
            line-height: 32px;
        }
    }
}
