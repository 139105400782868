/*
.d-info {
  position: relative;
  z-index: 100;
  opacity: .5;
  transition: all 2s cubic-bezier(0,0,0.2,1);

  &.show {
      bottom: calc(100% + 1em);
      transform: translate3d(0, -40px, 0);
      opacity: 1;
  }
}
*/

.d-info {
  position: relative;

  &-cont {
      padding: 10px;
      box-shadow: 0 0 10px 0px #000;
  }
  &-cont.dinfo {
      background: rgba(254, 255, 195, 0.9);
      border: 1px solid #cfd0a6;
  }
  &-cont.dwarn {
      background: rgba(234, 140, 140, 0.9);
      border: 1px solid #dc8782;
  }
   
  &__item {
    position: absolute;
    min-width: 190px;
    visibility: hidden;
    opacity: .8;
    transition: all 3s cubic-bezier(0, 0, 0.2, 1);
    z-index: 5011;
    /*bottom: 100%;*/
    bottom: 80px;
    left: 50%; 
    transform: translate3d(-50%, 0, 0);
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
    }
  }
  &__item.show {
      visibility: visible;
    /*bottom: 100%;*/
      bottom: 80px;
      transform: translate3d(-50%, -40px, 0);
      opacity: 1;
  }
  
  &__initiator {
    cursor: pointer;
    z-index: 5012;
  }

  &.oncell {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
  }
}

