$cell-bg-color: #feffc3;
$track-bg-color: #d0c7ae;
$track-border: 1px solid #b1a992;
$bg-color: #ffeaa8;
$cell-width: 148px;
$cell-height: 188px;

.bg-def {
    background-color: $track-bg-color;
}

.arrow-cell {
    background-color: $track-bg-color;
    display: flex;
    align-items: center;
    margin: 10px 0;
    border: $track-border;
}

.playground {
    position:absolute;
    top:60px;
    bottom:0;
    right:0;
    left:0;
    font-size: 12px;
    overflow: hidden;
    max-width: 2600px;
    margin: 0 auto;

    &_border {
        height: 100%;
        background: #568396;
	    /*background-image:url('../static/images/town.jpg');*/
    } 

    &-win { 
        position:absolute;
        overflow:auto;
        top: 0px;
        bottom: 0;
        right: 0px;
        left: 0px;
        text-align: center;
        cursor: grab;

        @media screen and (max-width:420px) {
            top:0px;
        }
        /*
        @media screen and (max-height:370px) {
            bottom: 0;
            right: 160px;
            border-right: 1px solid #353f44;
        }
        */
    }

    &-sky {
        padding: 180px;
        display: inline-block;
    }

    &-cont {
        position: relative;
        display: flex;
        padding: 10px;
        background: $bg-color;
        transform: scale(1);
        transition: 0.3s ease-in-out;
    }

    @media screen and (max-width:990px) {
        top:70px;
    }
    @media screen and (max-width:750px) {
        background:#a7a773;
    }
    @media screen and (max-width:420px) {
        top:86px;
    }
}

.fortune-cont {
    position: absolute;
    width: 140px;
    top: 300px;
    left: 300px;

    & h1 {
        font-weight:bold;
    }
    & ul {
        margin:10px 0;
    }
}

.start {
    position: relative;
	border: 1px solid #857d63;
    border-radius: 8px;
    box-shadow: 0px 0px 5px #777;
	background-color: $cell-bg-color;
    text-align: center;
    width: $cell-width;

    & .fortune-cont {
        position: relative;
        margin-top:10px;
        padding:10px 5px;
        color:#6D6D53;
        clear:left;
    }
}

.cells {

    .bg-cell {
        background: $track-bg-color;
        width: 130px;
        margin-right: 10px;
        border: $track-border;
    }
}

.cell_cont {
	height: $cell-height;
	position:relative;
	border:1px solid #bfb48f;
    background-color: $cell-bg-color;
    /*
    z-index: 1;
    */
    /*
    transition: transform 1s;
    transform-style: preserve-3d;
    */

    &.home {
        border: none;
        box-shadow: none;
        height: 100%;
    }
}
.flipped {
    transform: rotateY(180deg);
}
.fl-cont {
    width: 100%;
    height: 100%;
    position: absolute;
}
.cland {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000;

    & .dnum {
        width: 100%;
        height: 100%;
        color: #fff;
        /*
        color: #dbde0b;
        text-shadow: 3px 3px 3px #1d1d1d;
        */
        font-weight: bold;
        font-size: 6rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    & .dprob,
    & .dap {
        position: absolute;
        color: #fff;
        text-align: center;
        width: 100%;
    }
    & .dprob {
        bottom: 5px;
    }
    & .dap {
        bottom: 21px;
    }
}
.cell {
	height: $cell-height;
	width: $cell-width;
	text-align:center;
	position:relative;
	background-image:url('../static/images/cells.png');

    &.adven {
        background-position:0 -564px;
    }
    &.court {
        background-position:-148px -564px;
    }
    &.taxi {
        background-position:0 0;
    }
    &.ghetto {
        background-position:-148px 0;
    }
    &.police {
        background-position:-296px 0;
    }
    &.fortune {
        background-position:-444px 0;
    }
    &.school {
        background-position:-592px 0;
    }
    &.university {
        background-position:0 -188px;
    }
    &.bank {
        background-position:-148px -188px;
    }
    &.message {
        background-position:-296px -188px;
    }
    &.church {
        background-position:-444px -188px;
    }
    &.market {
        background-position:-592px -188px;
    }
    &.surprise {
        background-position:0 -376px;
    }
    &.employeeexchange {
        background-position:-148px -376px;
    }
    &.home {
        background-position:-296px -376px;
        border:none;
    }
    &.recruitmentagency {
        background-position:-444px -376px;
    }
    &.event {
        background-position:-592px -376px;
    }
    &.place {
        /*background: url('../static/images/cells_fields.png');
        background-position: 0px 0px;*/
        background-image: none;
    }
}

.food, .metal, .textile, .oil, .chips,
.food3D, .metal3D, .textile3D, .oil3D, .chips3D {
    background-image:url('../static/images/factories3D.png') !important;
}
.food.active, .metal.active, .textile.active, .oil.active, .chips.active,
.food3D.active, .metal3D.active, .textile3D.active,
.oil3D.active, .chips3D.active {
    background-image:url('../static/images/factories3Dcolor.png') !important;
}
.food3D, .food {
    background-position: 0 0;
}
.metal3D, .metal {
    background-position: -148px 0;
}
.textile3D, .textile {
    background-position: -296px 0;
}
.oil3D, .oil {
    background-position: -444px 0;
}
.chips3D, .chips {
    background-position: -592px 0;
}

.fact-cont {
    position: relative;
    cursor:pointer;
    float:left;
    width: $cell-width;
    height:160px;

    & h3 {
        text-align:center;
        font-weight:bold;
        margin:10px 0;
    }

    &.active h3 {
        color: #2b2;
    }

    @media screen and (max-width:420px) {
        float: none;
        margin: 0 auto;
    }
}

.log_cell {
    text-decoration: none;
    color: #3856a0;
}
.owned {
    border: 1px solid #777;
    border-radius: 4px;
}
.cell_title {
	font-weight:bold;
    padding:5px;
}
.cell_info {
    clear:both;
}
.cell_info span {
	font-weight:bold;
}

.cell_deal {
    background: #feffc3;
    margin: 0 auto;
    font-size: 12px;
    border: 1px solid #ccc;
}

.cid {
    background: $cell-bg-color;
}

/* ============ CLASSIC GAMEBOARD ========== */
.cell_corner {
    position: relative;
    border: 1px solid #bba778;
    height: 240px;
    width: 240px;
    float: left;
    background: $cell-bg-color;

    .cid {
        height: 100%;
    }

    .cell {
        /*margin: 25px auto 0;*/
        /*(240 - 148) / 2 = 46*/
        transform: translate(46px, 30px);
    }
}

.corner_top {
    margin-top: -2px;
}
.corner_bot {
    margin-bottom: -2px;
}

.cl-row {
    &-sm, &-md, &-lg, &-xl {
        padding: 0 2px;
        clear: left;
        overflow: hidden;
    }

    &-sm {
        width: 1530px;
    }

    &-md {
        width: 1530px;
    }

    &-lg {
        width: 1530px;
    }

    &-xl {
        width: 1530px;
    }
}

.cl-row-bot,
.cl-row-top {
    &-sm {
        width: 1530px;
    }

    &-md {
        width: 1530px;
    }

    &-lg {
        width: 1530px;
    }

    &-xl {
        width: 1530px;
    }
}

.cl-row-top {
    &-sm, &-md, &-lg, &-xl {
        padding-top: 2px;
        overflow: hidden;
    }
}

.cl-row-bot {
    &-sm, &-md, &-lg, &-xl {
        padding-bottom: 2px;
        overflow: hidden;
    }
}

.cl-top {
    margin-top: 4px;
    align-self: flex-start;
}
.cl-right {
    float: right!important;
    margin-right: 4px;
}
.cl-bottom {
    margin-top: 46px;
}
.cl-left {
    margin-left: 4px;
}
