html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, font, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
	margin:0;
	padding:0;
	border:0;
	outline:0;
	font-weight:inherit;
	font-style:inherit;
	font-size:100%;
	font-family:inherit;
	vertical-align:baseline;
}
html {
    height: 100%;
    background: #ece1c6;
}
body {
	line-height:1em;
	color:black;
	font-family: 'Exo 2', Helvetica, sans-serif;
	height:100%;
    background: #ece1c6;
}
ol, ul {
	list-style:none;
}
a {
    text-decoration: none;
    color: inherit;
}
table {
	border-collapse:collapse;
	border-spacing:0;
}
caption, th, td {
	text-align:left;
	font-weight:normal;
}
table th {
	padding:0 5px;
}
div {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
small {
    font-size: 70%;
    vertical-align: middle;
}
sup {
    vertical-align: super;
    font-size: 70%;
    color: #a36e35;
    padding-left: 3px;
}
input[type="text"],
input[type="number"] {
    width: 50px;
}
