
.table {
    width: 100%;
    max-width: 100%;

	border-collapse: collapse;
	border-spacing: 0;

    & th, & td {
        padding: 5px;
    }

    & th {
	    font-weight: bold;
    }
}

