.dialog__hint {
    padding: 20px;
    max-width: 480px;

    & h1 {
        text-align: center;
        font-weight: bold;
        color: #eb4102;
        margin-bottom: 20px;
    }

    & p {
        margin: 10px 0;
    }

    & a {
        font-weight: bold;
        color: #ad781c;
    }
}
.h_wait {
    background: url(../static/images/dialog/hourglass.png) 50% 50% no-repeat;
}
.h_info {
    background: url(../static/images/dialog/tip.png) 50% 50% no-repeat;
}
.h_taxi {
    background: url(../static/images/dialog/taxi.png) 50% 50% no-repeat;
}
.h_settings {
    background: url(../static/images/dialog/settings.png) 50% 50% no-repeat;
}
