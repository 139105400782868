/* tables */
table.tablesorter {
	font-family:Verdana,arial;
	background-color: #CDCDCD;
	margin:10px 0pt 15px;
	font-size: 8pt;
	width: 100%;
	text-align: left;
}
table.tablesorter thead tr th, table.tablesorter tfoot tr th {
	background-color: #e6EEEE;
	border: 1px solid #FFF;
	font-size: 8pt;
	padding: 4px;
    vertical-align:middle;
}
table.tablesorter thead tr .header {
	background-image: url(/static/images/tablesorter/bg.gif);
	background-repeat: no-repeat;
	background-position: center right;
	cursor: pointer;
	padding-right: 21px;
}
table.tablesorter tbody td {
	color: #3D3D3D;
	padding: 4px;
	background-color: #FFF;
	vertical-align:middle;
}
table.tablesorter tbody tr.odd td {
	background-color:#F0F0F6;
}
table.tablesorter thead tr .headerSortUp {
	background-image: url(/static/images/tablesorter/asc.gif);
}
table.tablesorter thead tr .headerSortDown {
	background-image: url(/static/images/tablesorter/desc.gif);
}
table.tablesorter thead tr .headerSortDown, table.tablesorter thead tr .headerSortUp {
background-color: #8dbdd8;
}
