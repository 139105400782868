.tabs {
}

.tabs.border {
  padding: 10px;
  border: 1px solid rgba(0,0,0,.2);
  background: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,.1);
}

.tab {
  padding: 20px 0 0;
  border-top: 1px solid #dec8b0;
}

.tabs .hr li {
  display: inline-block;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  color: #dec8b0;
  border: 1px solid transparent;
}

.tabs .hr li:hover {
  color: #c77724;
  cursor: pointer;
}

.tabs .hr li.active {
  color: #a36e35;
  border: 1px solid #dec8b0;
  border-top: 2px solid #dec8b0;
  border-bottom: 1px solid #fff;
}

@media screen and (max-width: 500px) {
  .tabs .hr.ht li {
    padding: 15px;
    /* hide text */
    font-size: 0px;
  }
  .tabs .hr li:before {
    margin: 0;
    font-size: 18px;
  }
}
