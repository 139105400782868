$bg-track: #ccc;
$bg-thumb: #fff;
$bg-thumb-dis: #aaa;
$width: 20px;
$thumb-radius: 10px;
$thumb-border: #777;
$track-height: 12.8px;
$track-radius: 4px;

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  background-color: transparent;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: $track-height;
  cursor: pointer;
  animate: 0.2s;
  background: $bg-track;
  border-radius: $track-radius;
}
input[type=range]::-webkit-slider-thumb {
  height: $width;
  width: $width;
  border-radius: $thumb-radius;
  background: $bg-thumb;
  border: 1px solid $thumb-border;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -3.6px;
}
input[type=range]:disabled::-webkit-slider-thumb {
  background: $bg-thumb-dis;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: $bg-track;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: $track-height;
  cursor: pointer;
  animate: 0.2s;
  background: $bg-track;
  border-radius: $track-radius;
}
input[type=range]::-moz-range-thumb {
  height: $width;
  width: $width;
  border-radius: $thumb-radius;
  background: $bg-thumb;
  border: 1px solid $thumb-border;
  cursor: pointer;
}
input[type=range]:disabled::-moz-range-thumb {
  background: $bg-thumb-dis;
}
input[type=range]::-ms-track {
  width: 100%;
  height: $track-height;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-radius: $track-radius;
  border-color: transparent;
  border-width: $width 0;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: $bg-track;
}
input[type=range]::-ms-fill-upper {
  background: $bg-track;
}
input[type=range]::-ms-thumb {
  border: 1px solid $thumb-border;
  height: $width;
  width: $width;
  border-radius: $thumb-radius;
  background: $bg-thumb;
  cursor: pointer;
}
input[type=range]:disabled::-ms-thumb {
  background: $bg-thumb-dis;
}
input[type=range]:focus::-ms-fill-lower {
  background: $bg-track;
}
input[type=range]:focus::-ms-fill-upper {
  background: $bg-track;
}
